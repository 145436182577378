import _ from 'lodash';

const analyzeBotReq = (logsArr) => {
  if (!Array.isArray(logsArr) || logsArr.length === 0) {
    return [];
  }
  let unknownReqArr = [];
  // console.log(logsArr);
  logsArr.map((item) => {
    if (
      (!item.referrer || item.referrer === '-') &&
      !item.url.startsWith('/api/v1/webhooks/stripe')
    ) {
      unknownReqArr.push(item);
    } else {
      let routeFoundSet = new Set();

      EXPOSED_SERVER_ROUTES.map((route) => {
        routeFoundSet.add(item.url.startsWith(route));
      });
      const routeFoundArr = Array.from(routeFoundSet);

      !routeFoundArr.includes(true) && unknownReqArr.push(item);
    }
  });
  // console.log({ unknownReqArr });
  //gather all status codes
  let statusCodeSet = new Set();
  unknownReqArr.map((item) => statusCodeSet.add(item.status));

  // final results
  let finalArr = [];
  finalArr.push(`Total Bot Requests: ${unknownReqArr.length}`);
  finalArr.push(
    `Response Status Codes: ${Array.from(statusCodeSet).join(', ')}`
  );
  finalArr.push(
    `Response Time ms: min: ${
      _.minBy(unknownReqArr, 'response-time')['response-time']
    }, max: ${
      _.maxBy(unknownReqArr, 'response-time')['response-time']
    }, mean: ${parseFloat(_.meanBy(unknownReqArr, 'response-time')).toFixed(3)}`
  );
  return finalArr;
};

export default analyzeBotReq;

const EXPOSED_SERVER_ROUTES = [
  // users
  '/api/v1/users/register',
  '/api/v1/users/login',
  '/api/v1/users/captcha',
  '/api/v1/users/google/login',
  '/api/v1/users/list',
  '/api/v1/users/export-list/', //:id
  '/api/v1/users/save-leads',
  '/api/v1/users/m-subs',
  '/api/v1/users',
  '/api/v1/users/block',
  '/api/v1/users/remove-captcha',
  '/api/v1/users/a-userlist',
  '/api/v1/users/a-upload-list',

  // leads
  '/api/v1/leads',
  '/api/v1/leads/category-suggestion',

  // my-leads
  '/api/v1/my-leads',

  // charges
  '/api/v1/charges',
  '/api/v1/charges/upgrade',
  '/api/v1/charges/active-sub',
  '/api/v1/charges/cancel',

  // articles
  '/api/v1/articles',
  '/api/v1/articles/', //:slug

  // jobs
  '/api/v1/jobs',

  // reports
  '/api/v1/reports',

  // analytics
  '/api/v1/analytics/categories',
  '/api/v1/analytics/memory',

  // webhooks
  '/api/v1/webhook',
  '/api/v1/webhook/stripe',
];
