import React, { useEffect, useRef } from 'react';
import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';
import { Button } from 'antd';

import { useImmer } from 'use-immer';
import moment from 'moment';
import axios from 'axios';
import { useActions } from '../../../hooks/useActions';

const ChartComponent = (props) => {
  const { getCandlesData } = useActions();

  const [assetCandlesData, setAssetCandlesData] = useImmer([]);

  const chartContainerRef = useRef();
  const {
    colors: {
      backgroundColor = 'white',
      lineColor = '#2962FF',
      textColor = 'black',
      areaTopColor = '#2962FF',
      areaBottomColor = 'rgba(41, 98, 255, 0.28)',
    } = {},
  } = props;

  const tradePositionObj = props.tradePosition || {};
  // console.log({ tradePositionObj });

  // roundup in miliseconds
  let openPositionDate = new Date(
    tradePositionObj['Opening Date Time']
  ).getTime();
  // console.log({ openPositionDate });

  openPositionDate = moment(openPositionDate);
  openPositionDate =
    openPositionDate.second() || openPositionDate.millisecond()
      ? openPositionDate.add(1, 'minute').startOf('minute')
      : openPositionDate.startOf('minute');

  openPositionDate = openPositionDate.toDate().getTime();

  // past time in milliseconds to get candles
  const oldestDate = moment(openPositionDate)
    .startOf('minute')
    .add(-150, 'minute')
    .toDate()
    .getTime();

  const newestDate = moment(openPositionDate)
    .startOf('minute')
    .add(50, 'minute')
    .toDate()
    .getTime();

  // console.log({ openPositionDate, oldestDate, newestDate });

  // get candles data of asset from Network request
  useEffect(() => {
    // network request to get data

    const getData = async () => {
      try {
        if (
          !tradePositionObj.Asset ||
          !oldestDate ||
          !newestDate ||
          isNaN(oldestDate) ||
          isNaN(newestDate)
        ) {
          console.log('data is missing, check it');
          return;
        }
        getCandlesData(
          tradePositionObj.Asset,
          oldestDate,
          newestDate,
          (tempCandlesData) => {
            // console.log(response);
            setAssetCandlesData(tempCandlesData);
          }
        );
      } catch (err) {
        console.log('Err: ', err.message);
      }
    };
    getData();
  }, [props.tradePosition]);

  // create candlesticks series chart
  useEffect(() => {
    if (!Array.isArray(assetCandlesData) || assetCandlesData.length === 0) {
      return;
    }
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: 410,
      localization: {
        locale: 'en',
        // dateFormat: 'hh:mm',
      },
      timeScale: {
        timeVisible: true,
        borderColor: '#D1D4DC',
      },
      // scaleMargins: {
      //   top: 0.1,
      //   bottom: 0.2,
      // },
      // priceFormat: {
      //   type: 'price',
      //   precision: 6,
      //   minMove: 0.000001,
      // },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: '#D1D4DC',
      },
      // priceScale: {
      //   autoScale: false,
      // },
      grid: {
        horzLines: {
          color: '#F0F3FA',
        },
        vertLines: {
          color: '#F0F3FA',
        },
      },
    });
    // chart.timeScale().fitContent();

    chart.timeScale().setVisibleLogicalRange({ from: 147, to: 153 });

    //for candelsticks series ---------------------------------------------------------

    const newSeries = chart.addCandlestickSeries({
      upColor: 'rgb(38,166,154)',
      downColor: 'rgb(255,82,82)',
      wickUpColor: 'rgb(38,166,154)',
      wickDownColor: 'rgb(255,82,82)',
      borderVisible: false,
    });
    newSeries.setData(assetCandlesData);

    window.addEventListener('resize', handleResize);

    // for line series for setting markers at horizontal line --------------------------------------------
    const lineSeries = chart.addLineSeries({
      color: 'rgba(255, 255, 255, 0)', // hide or show the line by setting opacity
      lastValueVisible: false, // hide value from y axis
      priceLineVisible: false,
    });
    // set markers

    // add Call/Put markers here
    const markers = [];

    markers.push({
      time: openPositionDate / 1000,
      // position: 'aboveBar',//belowBar
      position: 'inBar',
      color: tradePositionObj.Direction === 'call' ? '#21f32c99' : '#e91e6399',
      shape: tradePositionObj.Direction === 'call' ? 'arrowUp' : 'arrowDown',
      text:
        (Number(tradePositionObj['Net PnL']) > 0
          ? 'Win '
          : Number(tradePositionObj['Net PnL']) < 0
          ? 'Loss '
          : '0 ') + (tradePositionObj.Direction === 'call' ? 'Call' : 'Put'),
    });

    //line series data, it's the value for marker position, not whole candles ohlc data
    const lineSeriesData = [];

    lineSeriesData.push({
      time: openPositionDate / 1000,
      value: Number(tradePositionObj['Opening price']),
    });

    lineSeries.setData(lineSeriesData);

    lineSeries.setMarkers(markers);

    // end of line series------------------------------------------

    // return on end
    return () => {
      window.removeEventListener('resize', handleResize);

      chart.remove();
    };

    // end of use-effect
  }, [assetCandlesData]);

  return <div ref={chartContainerRef} />;
};

const ChartLib = (props) => {
  // const myAsset = (props.selectedAsset || '').replace(/\//, '');
  const tradesArr = props.tradesArr;

  // entered real trade
  // const [positionTrade, setPositionTrade] = useImmer({});
  const [activeIdx, setActiveIdx] = useImmer(0);

  // console.log(activeIdx);

  useEffect(() => {
    setActiveIdx(0);
  }, [props.tradesArr]);

  const nextTrade = () => {
    if (activeIdx < tradesArr.length - 1) {
      setActiveIdx(activeIdx + 1);
    } else {
      setActiveIdx(0);
    }
  };

  const prevTrade = () => {
    if (activeIdx <= 0) {
      setActiveIdx(tradesArr.length - 1);
    } else {
      setActiveIdx(activeIdx - 1);
    }
  };

  return (
    <>
      {/* data: is initial data */}
      <div
        style={{
          width: '200px',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '22px',
          marginTop: '22px',
        }}
      >
        <Button type="primary" onClick={prevTrade}>
          Prev
        </Button>
        <Button type="primary" onClick={nextTrade}>
          Next
        </Button>
      </div>
      <ChartComponent
        {...props}
        data={[]}
        tradePosition={tradesArr[activeIdx]}
      ></ChartComponent>
    </>
  );
};

export default ChartLib;
