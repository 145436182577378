import { Link } from 'react-router-dom';
import { Result } from 'antd';

const NotFound = (props) => {
  return (
    <div style={{ height: '100%' }}>
      <Result
        status="error"
        title="404 Page not Found"
        subTitle={null}
        extra={[
          <Link className="nav-link" to="/dashboard" key="404">
            Back to Dashboard
          </Link>,
        ]}
      />
    </div>
  );
};
export default NotFound;
