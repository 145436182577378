import {
  Pagination,
  Table,
  Select,
  Tag,
  Tooltip,
  Button,
  DatePicker,
  Input,
  Menu,
  Dropdown,
} from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { useActions } from '../../hooks/useActions';
import isEmpty from '../../validation/is-empty';
import { DownOutlined, MinusOutlined, RedoOutlined } from '@ant-design/icons';
import SearchBox from './SearchBox';
import styled from 'styled-components';

const { Option } = Select;

const StyledTable = styled(Table)`
  .ant-table-thead tr th:nth-of-type(odd) {
    background: #f3f3f3;
    border-bottom: 1px solid #d8d8d8;
    transition: background 0.3s;
  }
  .ant-table-thead tr th:nth-of-type(even) {
    border-bottom: 1px solid #d8d8d8;
    transition: background 0.3s;
  }

  .ant-table-tbody tr td:nth-of-type(odd) {
    background: #f3f3f3;
    border-bottom: 1px solid #d8d8d8;
    transition: background 0.3s;
  }

  .ant-table-tbody tr td:nth-of-type(even) {
    border-bottom: 1px solid #d8d8d8;
    transition: background 0.3s;
  }
`;

const EcomLead = (props) => {
  const [sortByObj, setSortByObj] = useImmer(sortByArr[0]);

  const [filterKey, setFilterKey] = useImmer([]);
  const [filterValue, setFilterValue] = useImmer([]);

  const [filterInput, setFilterInput] = useImmer('');
  const [filterError, setFilterError] = useImmer('');

  const { getEcomLeads } = useActions();
  const { page, ecomLeads, total, limit, selectedVertical } = useSelector(
    (state) => state.ecom
  );

  useEffect(() => {
    //get leads
    getLeads();
  }, [sortByObj]);

  useEffect(() => {
    // provider:local,subscription:premium
    const count =
      filterKey.length >= filterValue.length
        ? filterKey.length
        : filterValue.length;
    let filterQuery = '';
    for (let i = 0; i < count; i++) {
      filterQuery += `${filterKey[i]}:${filterValue[i]},`;
    }
    filterQuery = filterQuery.replace(/,$/, '');
    setFilterInput(filterQuery);
  }, [filterKey, filterValue]);

  const getLeads = (
    mPage = page,
    mLimit = limit,
    dateFrom = undefined,
    dateTo = undefined,
    callback
  ) => {
    const query = `page=${mPage}&limit=${mLimit}&sort=${
      sortByObj.sort
    }&filter=${
      dateFrom && dateTo ? `dateFrom:${dateFrom},dateTo:${dateTo}` : ''
    }${
      selectedVertical
        ? `,vertical:${encodeURIComponent(selectedVertical || '')}`
        : ''
    }${filterInput ? `,${filterInput}` : ''}`;

    // check for undefined in filter
    //there should be minimum one colon :, if filter is Not empty

    if (
      filterInput &&
      (filterInput.indexOf(':') === -1 ||
        filterInput.indexOf('undefined') !== -1)
    ) {
      console.log(
        'there should be one filter criteria and it must NOT contain undefined, if filter is NOT empty'
      );
      setFilterError('invalid filter');
      return;
    }

    setFilterError('');
    console.log(query);

    getEcomLeads(query, selectedVertical, (success) => {
      success && callback && callback();
    });
  };

  // for sort by select, index starts from 0
  const onSortByChange = (index) => {
    console.log(index, sortByArr[index].sort);

    setSortByObj(sortByArr[index]);
  };

  // for filter keys, key starts from 0
  const onFilterByKeyChange = ({ key }) => {
    const tempSet = new Set([...filterKey, filterKeyArr[key]]);

    setFilterKey(Array.from(tempSet));
  };

  const onFilterByValueChange = ({ key }) => {
    const tempSet = new Set([...filterValue, filterValueArr[key]]);

    setFilterValue(Array.from(tempSet));
  };

  const removeOneMenuValue = (typeKey) => {
    if (typeKey) {
      let tempKey = [...filterKey];
      tempKey.pop();
      setFilterKey(tempKey);
    } else {
      let tempVal = [...filterValue];
      tempVal.pop();
      setFilterValue(tempVal);
    }
  };

  const onFilterInputChange = (e) => {
    console.log(e.target.value);
    setFilterInput(e.target.value.trim());
  };

  const menuKey = (
    <Menu onClick={onFilterByKeyChange}>
      {filterKeyArr.map((item, idx) => {
        return <Menu.Item key={idx}>{item}</Menu.Item>;
      })}
    </Menu>
  );

  const menuValue = (
    <Menu onClick={onFilterByValueChange}>
      {filterValueArr.map((item, idx) => {
        return <Menu.Item key={idx}>{item}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <div style={{ padding: '1% 3%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px',
        }}
      >
        {/* pagination */}
        <div style={{ display: 'flex' }}>
          {/* total leads */}
          <div style={{ marginBottom: '4px', marginRight: '12px' }}>
            <span> Total leads: {total}</span>

            {/* re-fetch v-leads */}
            <span
              onClick={() => getLeads(page, limit)}
              style={{ cursor: 'pointer', marginLeft: '12px' }}
            >
              <Tooltip title="re-fetch Leads">
                <RedoOutlined />
              </Tooltip>
            </span>
          </div>
          <Pagination
            current={page}
            total={total}
            pageSize={limit}
            onChange={(page, pageSize) => {
              getLeads(page, pageSize);
            }}
            showSizeChanger={true}
            style={{ fontSize: '16px' }}
            showQuickJumper
          />
        </div>

        {/* search in bio or category */}
        <div
          style={{
            // marginBottom: '24px',
            maxWidth: '700px',
            marginLeft: '12px',
            width: '100%',
          }}
        >
          <SearchBox />
        </div>
      </div>

      {/* sort by & Date range */}
      <div style={{ display: 'flex' }}>
        {/* sort by */}
        <div
          className="testR1"
          style={{
            height: 'auto',
            fontWeight: 'bold',
            fontSize: '16px',
            marginRight: '24px',
          }}
        >
          Sort by:&nbsp;
          <Select
            value={sortByObj ? sortByObj.type : null}
            style={{ width: 240 }}
            onChange={onSortByChange}
          >
            {Array.isArray(sortByArr) &&
              sortByArr.map((itemObj, index) => (
                <Option key={index} value={index}>
                  {itemObj.type}
                </Option>
              ))}
          </Select>
        </div>

        {/* filter */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '22px',
          }}
        >
          {/* input filter */}
          <div style={{ display: 'flex' }}>
            <Input.TextArea
              value={filterInput}
              onChange={onFilterInputChange}
              autoSize
              onPressEnter={() => getLeads()}
              style={{
                borderColor: filterError && 'red',
              }}
            />
            <Button
              type="primary"
              style={{ marginLeft: '12px' }}
              onClick={() => getLeads()}
            >
              Filter
            </Button>
          </div>
          {filterError && <span style={{ color: 'red' }}>{filterError}</span>}
          <div style={{ display: 'flex' }}>
            {/* filter keys */}
            <div
              className="testR1"
              style={{
                height: 'auto',
                fontWeight: 'bold',
                fontSize: '16px',
                margin: '4px 12px',
              }}
            >
              <Button
                icon={<MinusOutlined />}
                onClick={() => removeOneMenuValue(true)}
              />
              <span style={{ margin: '4px 8px' }}>
                <Dropdown overlay={menuKey}>
                  <span>
                    Fields <DownOutlined />
                  </span>
                </Dropdown>
              </span>

              {/* <Button icon={<PlusOutlined />} /> */}
            </div>

            {/* filter values */}
            <div
              className="testR1"
              style={{
                height: 'auto',
                fontWeight: 'bold',
                fontSize: '16px',
                margin: '4px 12px',
              }}
            >
              <Button
                icon={<MinusOutlined />}
                onClick={() => removeOneMenuValue(false)}
              />
              <span style={{ margin: '4px 8px' }}>
                <Dropdown overlay={menuValue}>
                  <span>
                    Values <DownOutlined />
                  </span>
                </Dropdown>
              </span>
              {/* <Button icon={<PlusOutlined />} /> */}
            </div>
          </div>
        </div>

        {/* Date range */}
        <div>
          <DatePicker.RangePicker
            onChange={(value, dateString) => {
              console.log(dateString);
              getLeads(undefined, undefined, dateString[0], dateString[1]);
            }}
          />
        </div>
      </div>

      {/* v leads table */}
      <StyledTable
        columns={[...COLUMNS]}
        dataSource={ecomLeads}
        rowKey="_id"
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        pagination={false}
        size="small"
        showSorterTooltip={true}
        locale={{
          emptyText: !Array.isArray(ecomLeads) && (
            <div style={{ fontWeight: 'bolder', fontSize: '22px' }}>
              Loading...
            </div>
          ),
        }}
        bordered
        scroll={{ x: 'max-content', y: 650 }}
        sticky
      />
    </div>
  );
};

export default EcomLead;

const filterKeyArr = [
  'valid',
  'update_no',
  'country',
  'klaviyo',
  'fb_pixel',
  'loox',
  'mailchimp',
];
const filterValueArr = [0, true, false, 'US'];

const sortByArr = [
  { type: 'Oldest Created Date', sort: 'createdAt' },
  { type: 'Newest Created Date', sort: '-createdAt' },

  {
    type: 'Mail Ready',
    sort: '-mail_ready,-updatedAt',
  },
  {
    type: 'Mail Not Ready',
    sort: 'mail_ready,-updatedAt',
  },

  {
    type: 'Content Ready',
    sort: '-mail_content,-updatedAt',
  },
  {
    type: 'Content Not Ready',
    sort: 'mail_content,-updatedAt',
  },

  {
    type: 'Mail Open',
    sort: '-mail_open,-updatedAt',
  },
  {
    type: 'Mail Not Open',
    sort: 'mail_open,-updatedAt',
  },

  {
    type: 'Link Click',
    sort: '-mail_link_click,-updatedAt',
  },
  {
    type: 'Link Not Click',
    sort: 'mail_link_click,-updatedAt',
  },

  {
    type: 'Category (Desc)',
    sort: 'category,-updatedAt',
  },
  {
    type: 'Category (Asc)',
    sort: '-category,-updatedAt',
  },

  {
    type: 'Unsubscribed',
    sort: '-mail_unsubscribed,-updatedAt',
  },
  {
    type: 'Not Unsubscribed',
    sort: 'mail_unsubscribed,-updatedAt',
  },

  {
    type: 'Mail Disabled',
    sort: '-mail_disabled,-updatedAt',
  },

  {
    type: 'Not Disabled',
    sort: 'mail_disabled,-updatedAt',
  },

  { type: 'Newest Updated Date', sort: '-updatedAt' },
  { type: 'Oldest Updated Date', sort: 'updatedAt' },
];

const COLUMNS = [
  {
    title: 'Valid',
    dataIndex: 'valid',
    key: 'valid',
    fixed: 'left',
    width: 50,
    render: (text, record) => (
      <>{typeof text === 'boolean' && !text && <Tag color="#f50"> NO</Tag>}</>
    ),
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    fixed: 'left',
    render: (text, record) => (
      <a href={'https://' + text} rel="noopener noreferrer" target="_blank">
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </a>
    ),
  },
  // tech installed
  {
    title: 'Klaviyo',
    dataIndex: 'klaviyo',
    key: 'klaviyo',
    width: 70,
    render: (text, record) => (
      <>{typeof text === 'boolean' && !!text && <Tag color="#f50"> YES</Tag>}</>
    ),
  },
  {
    title: 'FB Pixel',
    dataIndex: 'fb_pixel',
    key: 'fb_pixel',
    width: 70,
    render: (text, record) => (
      <>{typeof text === 'boolean' && !!text && <Tag color="#f50"> YES</Tag>}</>
    ),
  },
  {
    title: 'Loox',
    dataIndex: 'loox',
    key: 'loox',
    width: 50,
    render: (text, record) => (
      <>{typeof text === 'boolean' && !!text && <Tag color="#f50"> YES</Tag>}</>
    ),
  },
  {
    title: 'Mailchimp',
    dataIndex: 'mailchimp',
    key: 'mailchimp',
    width: 100,
    render: (text, record) => (
      <>{typeof text === 'boolean' && !!text && <Tag color="#f50"> YES</Tag>}</>
    ),
  },

  {
    title: 'Location On Site',
    dataIndex: 'location_on_site',
    key: 'location_on_site',
    render: (text, record) => (
      <a href={'https://' + text} rel="noopener noreferrer" target="_blank">
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </a>
    ),
  },
  {
    title: 'Tech Spend USD',
    dataIndex: 'tech_spend_usd',
    key: 'tech_spend_usd',
    width: 130,
  },

  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 100,
    render: (text, record) => (
      <span>
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </span>
    ),
  },

  {
    title: 'Vertical',
    dataIndex: 'vertical',
    key: 'vertical',
  },

  {
    title: 'Quantcast',
    dataIndex: 'quantcast',
    key: 'quantcast',
  },
  {
    title: 'Alexa',
    dataIndex: 'alexa',
    key: 'alexa',
  },
  {
    title: 'Majestic',
    dataIndex: 'majestic',
    key: 'majestic',
  },
  {
    title: 'Umbrella',
    dataIndex: 'umbrella',
    key: 'umbrella',
  },
  {
    title: 'Telephones',
    dataIndex: 'telephones',
    key: 'telephones',
    width: 100,
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
  },
  {
    title: 'Emails',
    dataIndex: 'emails',
    key: 'emails',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
  },

  {
    title: 'Twitter',
    dataIndex: 'twitter',
    key: 'twitter',
    width: 70,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'Facebook',
    dataIndex: 'facebook',
    key: 'facebook',
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'LinkedIn',
    dataIndex: 'linkedIn',
    key: 'linkedIn',
    width: 70,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'Google',
    dataIndex: 'google',
    key: 'google',
    width: 70,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'Pinterest',
    dataIndex: 'pinterest',
    key: 'pinterest',
    width: 100,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'GitHub',
    dataIndex: 'gitHub',
    key: 'gitHub',
    width: 70,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'Instagram',
    dataIndex: 'instagram',
    key: 'instagram',
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'VK',
    dataIndex: 'vk',
    key: 'vk',
    width: 50,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'Vimeo',
    dataIndex: 'vimeo',
    key: 'vimeo',
    width: 70,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'Youtube',
    dataIndex: 'youtube',
    key: 'youtube',
    width: 70,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'TikTok',
    dataIndex: 'tiktok',
    key: 'tiktok',
    width: 70,
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 10)}
            {`${text}`.length > 10 && '...'}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'People',
    dataIndex: 'people',
    key: 'people',
    width: 100,
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
  },

  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    width: 70,
    render: (text, record) => (
      <span>
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </span>
    ),
  },

  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: 50,
    render: (text, record) => (
      <span>
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </span>
    ),
  },

  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
    width: 50,
  },

  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 70,
  },

  {
    title: 'First Detected',
    dataIndex: 'first_detected',
    key: 'first_detected',
    width: 120,
  },
  {
    title: 'Last Found',
    dataIndex: 'last_found',
    key: 'last_found',
  },
  {
    title: 'First Indexed',
    dataIndex: 'first_indexed',
    key: 'first_indexed',
    width: 100,
  },

  {
    title: 'Last Indexed',
    dataIndex: 'last_indexed',
    key: 'last_indexed',
    width: 100,
  },

  {
    title: 'Exclusion',
    dataIndex: 'exclusion',
    key: 'exclusion',
    width: 80,
  },

  {
    title: 'Compliance',
    dataIndex: 'compliance',
    key: 'compliance',
    width: 100,
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },

  {
    title: 'Update No',
    dataIndex: 'update_no',
    key: 'update_no',
    fixed: 'right',
    width: 100,
  },
];
