import GoogleLogin from 'react-google-login';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { GOOGLE_OAUTH2_CLIENT_ID } from '../../constants';
import { useActions } from '../../hooks/useActions';

const StyledGoogleLogin = styled(GoogleLogin)`
  width: 100%;
  background-color: black !important;
`;

const AuthWithGoogle = ({ isLogin }) => {
  const { loginWithGoogle } = useActions();
  const [message, setMessage] = useImmer(['']);

  const handleGoogleLogin = async (googleData) => {
    setMessage(['']);
    const { tokenId } = googleData;

    !!tokenId &&
      loginWithGoogle(tokenId, (msg) => {
        msg && setMessage([msg]);
      });
  };
  return (
    <>
      <StyledGoogleLogin
        clientId={GOOGLE_OAUTH2_CLIENT_ID}
        buttonText={
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {isLogin ? 'Log In' : 'Sign Up'} with Google
          </span>
        }
        onSuccess={handleGoogleLogin}
        onFailure={handleGoogleLogin}
        cookiePolicy="single_host_origin"
        theme="dark"
      />
      {/* Error message */}
      <div style={{ color: 'red' }}>
        {Array.isArray(message) &&
          message.map((item, idx) => <div key={idx}>{item}</div>)}
      </div>
    </>
  );
};

export default AuthWithGoogle;
