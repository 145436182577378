import produce from 'immer';
import { SET_V_LEADS } from '../action-types';

const INITIAL_STATE = {
  page: 1,
  total: null,
  limit: 100,
  vLeads: [],
  selectedCategory: '',
  bioSearchText: '',
  searchSuggestion: [],
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_V_LEADS:
        const { page, vLeads, total, limit, selectedCategory, bioSearchText } =
          action.payload;

        draft.page = page;
        draft.vLeads = vLeads;
        draft.total = total;
        draft.limit = limit;

        draft.selectedCategory = selectedCategory || '';
        draft.bioSearchText = bioSearchText || '';

        break;
      default:
        return state;
    }
  });

export default reducer;
