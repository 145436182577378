/**
 * @memberof module:util
 * @function
 * @name tryParseJSON
 * @description It parses json string to JSON. If it can't be parsed, then it returns false.
 *
 * @param {string} jsonString - json string to be parsed to json object
 * @returns {Object | boolean}
 *
 * @example
 * console.log(tryParseJSON()); //false
 * console.log(tryParseJSON(null)); //false
 *
 * console.log(tryParseJSON("{\"foo\":10}")); //{foo: 10}
 * console.log(typeof tryParseJSON("{\"foo\":10}")); //object
 */

export const tryParseJSON = (jsonString) => {
  // console.log("jsonString ..:", jsonString);
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    console.log('Err: ', e.message);
  }

  return false;
};
