import produce from 'immer';
import { SET_ITH_REVIEWS } from '../action-types';

const INITIAL_STATE = {
  page: 1,
  total: null,
  limit: 100,
  reviews: [],
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ITH_REVIEWS:
        const { page, reviews, total, limit } = action.payload;

        draft.page = page;
        draft.reviews = reviews;
        draft.total = total;
        draft.limit = limit;

        break;
      default:
        return state;
    }
  });

export default reducer;
