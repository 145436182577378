import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import moment from 'moment';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import _ from 'lodash';
import Price from '../../utils/Price';

const COLOR_GREEN = '#71ff5e';
const COLOR_RED = '#ff4949';
const COLOR_XAXIS = '#c9d884';
const COLOR_YAXIS = '#8884d8';

const ConsecutiveWLBarChart = ({ tradesArr }) => {
  //sort as per Closing Date Time
  const sortedAllTradesArr = _.sortBy(tradesArr, (o) =>
    Date.parse(o['Closing Date Time'])
  );

  const [chartDataArr, setChartDataArr] = useImmer({});

  useEffect(() => {
    // consecutive win obj : {4: 2}, it means consecutive 4 wins in a row (regardless of amount traded) happened twice
    let tempWinObj = {};
    // consecutive loss obj : {3: 4}, it means consecutive 3 losses in a row happened 4 times
    let tempLossObj = {};

    let tempWinRow = 0;
    let tempLossRow = 0;

    if (Array.isArray(sortedAllTradesArr)) {
      sortedAllTradesArr.map((itemObj) => {
        if (Number(itemObj['Net PnL']) > 0) {
          // update loss in a row
          if (tempLossRow > 0) {
            // console.log({ tempLossRow });

            tempLossObj = {
              ...tempLossObj,
              [`${tempLossRow}`]:
                (Number(tempLossObj[`${tempLossRow}`]) || 0) + 1,
            };
            tempLossRow = 0;
          }
          tempWinRow += 1;
        } else if (Number(itemObj['Net PnL']) < 0) {
          // update win in a row
          if (tempWinRow > 0) {
            // console.log({ tempWinRow });

            tempWinObj = {
              ...tempWinObj,
              [`${tempWinRow}`]: (Number(tempWinObj[`${tempWinRow}`]) || 0) + 1,
            };

            tempWinRow = 0;
          }
          tempLossRow += 1;
        }
      });

      // make double bar chart data
      let tempConsecutiveWinLossSet = new Set();

      Object.keys(tempWinObj).map((item) =>
        tempConsecutiveWinLossSet.add(item)
      );
      Object.keys(tempLossObj).map((item) =>
        tempConsecutiveWinLossSet.add(item)
      );

      let chartDataArr = [];

      Array.from(tempConsecutiveWinLossSet).map((item) => {
        if (tempWinObj[`${item}`] || tempLossObj[`${item}`]) {
          chartDataArr.push({
            consecutiveWinLoss: Number(`${item}`),
            win: Number(tempWinObj[`${item}`] || 0),
            loss: Number(tempLossObj[`${item}`] || 0),
          });
        }
      });

      // console.log({ chartData });

      setChartDataArr(chartDataArr);
    }
  }, [tradesArr]);

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log({ active, payload, label });
    if (active && payload && payload.length) {
      return (
        <div
          className="testR1"
          style={{
            background: '#000e07',
            padding: '18px',
            borderRadius: '12px',
            color: 'white',
          }}
        >
          {payload.map((itemObj) => {
            return (
              <div
                style={{
                  color:
                    `${itemObj.name}`.toLowerCase() === 'win' ? 'green' : 'red',
                }}
              >
                {itemObj.value ? (
                  <span>
                    Consecutive&nbsp;
                    <span style={{ textTransform: 'uppercase' }}>
                      {itemObj.name}
                    </span>
                    &nbsp;in a row is&nbsp;
                    {itemObj.payload.consecutiveWinLoss}, which is
                    happened&nbsp;
                    {itemObj.value} times.
                  </span>
                ) : null}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {/* For whole CSV View */}

      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={chartDataArr}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="consecutiveWinLoss" tick={{ fill: COLOR_XAXIS }} />
          <YAxis tick={{ fill: COLOR_YAXIS }} />

          <RechartsTooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="win" fill={COLOR_GREEN} />
          <Bar dataKey="loss" fill={COLOR_RED} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default ConsecutiveWLBarChart;
