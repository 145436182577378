import produce from 'immer';
import { SET_CONFIG, SET_TRADE_CONFIG } from '../action-types';

// import isEmpty from '../../validation/is-empty';

const INITIAL_STATE = {
  playSound: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_TRADE_CONFIG:
        const { playSound } = action.payload;

        if (action.payload.hasOwnProperty('playSound')) {
          draft.playSound = !!playSound;
        }

        break;
      default:
        return state;
    }
  });

export default reducer;
