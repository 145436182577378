import { Upload, message, Table, Tooltip, Button } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import analyzeServerLog from '../../utils/analyzeServerLog';
import moment from 'moment';
import analyzeBotReq from '../../utils/analyzeBotReq';

const Logs = (props) => {
  const [selectedFile, setSelectedFile] = useImmer([]);
  const [selectedFileLogs, setSelectedFileLogs] = useImmer('');
  const [logsArr, setLogsArr] = useImmer([]);
  const [mismatchLogsArr, setMismatchLogsArr] = useImmer([]);

  useEffect(() => {
    analyzeLogsNow();
  }, [selectedFileLogs]);

  const getTextFromFile = (file) => {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = () => {
      const tempText = reader.result;

      setSelectedFileLogs(tempText);
    };
  };

  const analyzeLogsNow = () => {
    const { logs = [], mismatchLogs = [] } = analyzeServerLog(selectedFileLogs);

    if (Array.isArray(logs)) {
      const logsArr = logs.map((item, idx) => ({ ...item, idx }));
      setLogsArr(logsArr);
    }

    if (Array.isArray(mismatchLogs)) {
      setMismatchLogsArr(mismatchLogs);
    }
  };

  const beforeUpload = (file) => {
    setSelectedFile([file]);
    getTextFromFile(file);
    const isLog = file.name.endsWith('.log');

    if (!isLog) {
      message.error('You can only load Log file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 500;
    if (!isLt2M) {
      message.error('Image must smaller than 500MB!');
    }
    return false; //make it false in production, stop uploading, otherwise it throws an error while try to upload
  };

  return (
    <div style={{ padding: '3%' }}>
      <div>Campaign</div>
      {/* Select Logs */}
      <Upload
        listType="picture-card"
        fileList={selectedFile}
        beforeUpload={beforeUpload}
        onRemove={() => {
          setSelectedFile([]);
          setSelectedFileLogs('');
        }}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Choose File</div>
        </div>
      </Upload>
      {/* Analyze Bot Requests button */}
      <Button
        onClick={() => {
          message.info({
            content: (
              <>
                {analyzeBotReq(logsArr).map((item, idx) => (
                  <div
                    key={idx}
                    style={{ background: idx % 2 === 0 && '#bbcccc50' }}
                  >
                    {item}
                  </div>
                ))}
              </>
            ),
            duration: 0,
            onClick: () => message.destroy(),
          });
        }}
        style={{ marginRight: '12px' }}
      >
        Show Bot Req Stats
      </Button>
      {/* Mismatch Log button */}
      <Button
        onClick={() => {
          message.error({
            content: (
              <>
                {mismatchLogsArr.map((item, idx) => (
                  <div key={idx}>{item}</div>
                ))}
              </>
            ),
            duration: 0,
            onClick: () => message.destroy(),
          });
        }}
      >
        Show Mismatch Logs
      </Button>

      {/* total logs */}
      <div>Total Records: {logsArr.length}</div>
      {/* logs Table */}
      <Table
        columns={COLUMNS}
        dataSource={logsArr}
        rowKey="idx"
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        pagination={false}
        size="large"
        showSorterTooltip={false}
      />
    </div>
  );
};

export default Logs;

const COLUMNS = [
  {
    title: 'IP',
    dataIndex: 'remote-addr',
    key: 'remote-addr',
    render: (text, record) => (
      <>
        {text && (
          <a
            href={`https://whatismyipaddress.com/ip/${text}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${text}`}
          </a>
        )}
      </>
    ),
    sorter: (a, b) =>
      `${a['remote-addr']}`.localeCompare(`${b['remote-addr']}`),
  },
  {
    title: 'User',
    dataIndex: 'remote-user',
    key: 'remote-user',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
    sorter: (a, b) =>
      `${a['remote-user']}`.localeCompare(`${b['remote-user']}`),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
    sorter: (a, b) => `${a['date']}`.localeCompare(`${b['date']}`),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Method',
    dataIndex: 'method',
    key: 'method',
    sorter: (a, b) => `${a['method']}`.localeCompare(`${b['method']}`),
  },
  {
    title: 'Request Url',
    dataIndex: 'url',
    key: 'url',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
    sorter: (a, b) => `${a['url']}`.localeCompare(`${b['url']}`),
  },
  {
    title: 'HTTP',
    dataIndex: 'http-version',
    key: 'http-version',
    sorter: (a, b) =>
      `${a['http-version']}`.localeCompare(`${b['http-version']}`),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => `${a['status']}`.localeCompare(`${b['status']}`),
  },
  {
    title: 'Res Time ms',
    dataIndex: 'response-time',
    key: 'response-time',
    sorter: (a, b) => a['response-time'] - b['response-time'],
  },

  {
    title: 'Res C-Length',
    dataIndex: 'res',
    key: 'res',
    sorter: (a, b) => `${a['res']}`.localeCompare(`${b['res']}`),
  },
  {
    title: 'Referrer',
    dataIndex: 'referrer',
    key: 'referrer',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
    sorter: (a, b) => a['referrer'].localeCompare(b['referrer']),
  },

  {
    title: 'User Agent',
    dataIndex: 'user-agent',
    key: 'user-agent',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
    sorter: (a, b) => a['user-agent'].localeCompare(b['user-agent']),
  },
];
