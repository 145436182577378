import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import AuthWithGoogle from './AuthWithGoogle';
import { useSelector } from 'react-redux';

const Login = (props) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    isAuthenticated && navigate('/journal');
  }, [isAuthenticated]);

  return (
    <div
      style={{
        background: 'linear-gradient(to right, #3b82f6 1%, #1e3a8a 99%)',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="testR1"
    >
      <h1
        style={{
          position: 'absolute',
          top: 0,
          marginTop: '20px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '28px',
          textAlign: 'center',
        }}
        className="testR1"
      >
        Leads Houzz
      </h1>

      <div
        style={{ maxHeight: '99%', maxWidth: '90%', overflow: 'auto' }}
        className="testR1"
      >
        <Card
          style={{
            width: '100%',
            maxWidth: 400,
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="testR1"
        >
          {/* Google auth */}
          <AuthWithGoogle isLogin={true} />
        </Card>
      </div>
    </div>
  );
};

export default Login;
