import setAuthToken from '../../utils/setAuthToken';
import { SET_CURRENT_USER } from '../action-types';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { updateConfig } from '../action-creators';

export const loginWithGoogle = (token, callback) => async (dispatch) => {
  try {
    dispatch(updateConfig({ loading: true }));

    const res = await axios.post('/api/v1/users/login', {
      token,
    });

    const jwt_token = res.data.token;

    dispatch(setCurrentUser(jwt_token));
  } catch (err) {
    console.log('Error: ', err.message);
    callback(err?.response?.data?.message || err.message);
  }

  dispatch(updateConfig({ loading: false }));
};

export const setCurrentUser = (jwt_token) => async (dispatch) => {
  if (!jwt_token) {
    return;
  }

  // Set auth token header auth
  setAuthToken(jwt_token);

  // Save to localStotage
  localStorage.setItem('jwt_token', jwt_token);

  // Decode jwt_token and get user info i.e. email

  try {
    let decoded = jwt_decode(jwt_token);
    // console.log({ decoded });
    decoded = decoded || {};

    dispatch({
      type: SET_CURRENT_USER,
      payload: { login_user: decoded },
    });
  } catch (err) {
    console.log('Error: ', err.message);
    deleteAccessToken();
  }
};

// Log user out
export const deleteAccessToken = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem('jwt_token');

  // Remove auth header for future requests
  setAuthToken();

  // Set current user
  dispatch({
    type: SET_CURRENT_USER,
  });
};
