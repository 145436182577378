import produce from 'immer';
import { SET_CONFIG } from '../action-types';

// import isEmpty from '../../validation/is-empty';

const INITIAL_STATE = {
  loading: false,
  message: '',
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CONFIG:
        const { loading, message } = action.payload;

        if (action.payload.hasOwnProperty('loading')) {
          draft.loading = !!loading;
        }

        if (action.payload.hasOwnProperty('message')) {
          draft.message = message;
        }

        break;
      default:
        return state;
    }
  });

export default reducer;
