import { useImmer } from 'use-immer';
import { useEffect, useRef } from 'react';
import moment from 'moment';

import _ from 'lodash';
import Price from '../../utils/Price';
import TradingViewWidget from './TradingViewWidget';
import {
  Button,
  Checkbox,
  Input,
  InputNumber,
  Modal,
  Select,
  Tag,
  TimePicker,
  Tooltip,
  message,
} from 'antd';
import { useActions } from '../../hooks/useActions';
import {
  getNewsStarWaitingTime,
  getSessionStr,
  saveNewsStarWaitingTime,
} from '../../utils/getSessionStr';
import MyTimeline from './MyTimeline';
import { useSelector } from 'react-redux';
import {
  STAR_1_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
  STAR_2_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
  STAR_3_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
} from '../../constants';

const COLOR_GREEN = '#71ff5e';
const COLOR_RED = '#ff4949';
const COLOR_YELLOW = '#c9d884';
const COLOR_GRAY = '#dbdbd8';
const COLOR_MAGENTA = '#db00d090'; //for trade color

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// session data object
//  news release data is the session object here:
//{ date: dayDate, session_data: [{ name: "USD",star: "1", time: "10:15"}, {name:"JPY:EUR", star:"3", "03:02"}]}

const SessionTimeSeries = ({
  newsDataArr,
  selectedDate,
  tradesArr,
  title,
  showBinarySession,
}) => {
  const myDefaultSessRef = useRef();

  const { getQtxRealFxData, updateTradeConfig } = useActions();
  // console.log({ time });

  const { playSound } = useSelector((state) => state.trade);

  // get qtx real fx data
  const [qtxRealFx, setQtxRealFx] = useImmer([]);
  const [qtxRewardPct, setQtxRewardPct] = useImmer(70);
  const [qtxRealFxDate, setQtxRealFxDate] = useImmer();
  const [watchAssetName, setWatchAssetName] = useImmer('EUR/JPY');

  // news event stars waiting time in minutes
  const [newsStar1, setNewsStar1] = useImmer(0);
  const [newsStar2, setNewsStar2] = useImmer(0);
  const [newsStar3, setNewsStar3] = useImmer(0);

  const {
    newsSessTvStr,
    substractedSessTvStr,
    defaultSessArr,
    newsSessArr,
    substarctedSessionArr,
  } = getSessionStr(newsDataArr);

  useEffect(() => {
    getNewsStarWaitTimeNow();
  }, []);

  const getNewsStarWaitTimeNow = () => {
    try {
      const getNewsStarWaitingTimeObj = getNewsStarWaitingTime();

      setNewsStar1(getNewsStarWaitingTimeObj.star1);

      setNewsStar2(getNewsStarWaitingTimeObj.star2);

      setNewsStar3(getNewsStarWaitingTimeObj.star3);
    } catch (err) {
      console.log('Error: ', err.message);
    }
  };

  // console.log({
  //   newsSessTvStr,
  //   substractedSessTvStr,
  //   defaultSessArr,
  //   newsSessArr,
  //   substarctedSessionArr,
  // });

  useEffect(() => {
    //get initial data
    getQtxRealFxDataNow();
    // fetch qtx fx real data in each 5 mins
    const timer_id = setInterval(getQtxRealFxDataNow, 1 * 60 * 1000);

    return () => {
      timer_id && clearInterval(timer_id);
    };
  }, []);

  // get qtx real fx data
  const getQtxRealFxDataNow = () => {
    try {
      setQtxRealFx([]);
      getQtxRealFxData((success, data) => {
        // console.log('getQtxRealFxDataNow', { success, data });

        if (!success) return;

        const tempData = _.orderBy(data.pairs, 'reward', 'desc');
        setQtxRealFx(tempData);
        setQtxRealFxDate(data.updatedAt);
      });
    } catch (err) {
      console.log('Err: ', err.message);
    }
  };

  // play sound if eur/jpy pair has more than 80% reward
  let timer_id;
  useEffect(() => {
    const soundMp3 = new Audio('/sound.mp3');

    timer_id && clearInterval(timer_id);
    // for playing sound alert
    timer_id = setInterval(() => {
      qtxRealFx.map((itemObj) => {
        if (
          (itemObj.name || '')
            .toLowerCase()
            .includes((watchAssetName || '').toLowerCase())
        ) {
          let reward = (itemObj.reward || '').replace('%', '').trim();
          reward = Number(reward);

          // play sound
          playSound && reward >= qtxRewardPct && soundMp3.play();
        }
      });
    }, 30 * 1000);

    return () => clearInterval(timer_id);
  }, [qtxRealFx, qtxRewardPct, watchAssetName]);

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
          }}
        >
          {/* news session trading view format string */}
          {showBinarySession && (
            <Button
              onClick={() => {
                //copy to clipboard

                if (!newsSessTvStr) {
                  message.error('Empty News Session String');
                  return;
                }

                navigator.clipboard.writeText(newsSessTvStr);
              }}
              style={{ margin: '12px 0px' }}
              type="warn"
              disabled={!newsSessTvStr || !navigator.clipboard}
            >
              For TradingView (TV News Session Str)
            </Button>
          )}

          {/* substracted sess TV */}
          {showBinarySession && (
            <Button
              onClick={() => {
                //copy to clipboard

                if (!substractedSessTvStr) {
                  message.error('Empty News Session String');
                  return;
                }

                // covert date format 01-08-2023 to 1-8-2023 (D/M/YYYY for chrome ext. tampermonkey script)
                const tempDate = (selectedDate || '').split('-');

                navigator.clipboard.writeText(
                  `${
                    parseInt(tempDate[0]) +
                    '/' +
                    parseInt(tempDate[1]) +
                    '/' +
                    parseInt(tempDate[2])
                  }#${substractedSessTvStr}`
                );
              }}
              style={{ margin: '12px 0px' }}
              type="danger"
              disabled={!substractedSessTvStr || !navigator.clipboard}
            >
              For Chrome Ext. (TV Remaining Session Str)
            </Button>
          )}

          {/* qtx star time */}
          <div style={{ margin: '4px 0' }}>
            News Wait Time (in min) Star 3&nbsp;
            <InputNumber
              min={1}
              max={100}
              value={newsStar3}
              onChange={(val) => {
                saveNewsStarWaitingTime(
                  STAR_3_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
                  val
                );
                getNewsStarWaitTimeNow();
              }}
              style={{
                width: 60,
              }}
            />
            &nbsp;Star 2&nbsp;
            <InputNumber
              min={1}
              max={100}
              value={newsStar2}
              onChange={(val) => {
                saveNewsStarWaitingTime(
                  STAR_2_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
                  val
                );
                getNewsStarWaitTimeNow();
              }}
              style={{
                width: 60,
              }}
            />
            &nbsp;Star 1&nbsp;
            <InputNumber
              min={1}
              max={100}
              value={newsStar1}
              onChange={(val) => {
                saveNewsStarWaitingTime(
                  STAR_1_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
                  val
                );
                getNewsStarWaitTimeNow();
              }}
              style={{
                width: 60,
              }}
            />
          </div>
        </div>
        {/* substracted session trading view format string */}
        {/* <Button
          onClick={() => {
            //copy to clipboard

            if (!substractedSessTvStr) {
              message.error('Empty News Session String');
              return;
            }

            navigator.clipboard.writeText(substractedSessTvStr);
          }}
          style={{ margin: '12px 10px' }}
          type="warn"
          disabled={!substractedSessTvStr || !navigator.clipboard}
        >
          Copy TV Substracted Clean Session String
        </Button> */}
      </div>
      {/* Quotex Real Fx Data */}
      <div
        style={{
          margin: '22px 0 8px',
          minHeight: '100px',
        }}
      >
        {/* <Button type="primary" onClick={getQtxRealFxDataNow}>
          Get Qtx Real Fx
        </Button> */}
        {/* updated date */}
        {qtxRealFxDate && (
          <div style={{ margin: '8px 0 2px' }}>
            <Tag color="red" style={{ cursor: 'pointer' }}>
              Qtx Reward: {moment.utc(qtxRealFxDate).fromNow()}
            </Tag>
            <div style={{ margin: '4px 0' }}>
              <Checkbox
                checked={playSound}
                onChange={() => updateTradeConfig({ playSound: !playSound })}
                style={{
                  color: 'blue',
                }}
              >
                Play Sound when&nbsp;
                <Input
                  value={watchAssetName}
                  onChange={(e) => {
                    setWatchAssetName(e.target.value);
                  }}
                  style={{
                    width: 100,
                  }}
                />
                &nbsp;is&nbsp;
                <InputNumber
                  min={1}
                  max={100}
                  value={qtxRewardPct}
                  onChange={(val) => {
                    setQtxRewardPct(val);
                  }}
                  style={{
                    width: 60,
                  }}
                />
                &nbsp;% up
              </Checkbox>
            </div>
          </div>
        )}
        {/* render list of fx data */}
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            margin: '8px 0',
          }}
        >
          {Array.isArray(qtxRealFx) &&
            qtxRealFx.map((itemObj, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    margin: '2px 0',
                    ...((watchAssetName || '')
                      .toLowerCase()
                      .trim()
                      .includes((itemObj.name || '').toLowerCase().trim()) && {
                      color: 'yellow',
                    }),
                  }}
                >
                  {itemObj.name}: {itemObj.reward}
                </div>
              );
            })}
        </div>
      </div>

      {/* time series */}
      <div style={{ marginTop: '40px' }} className="testR1">
        {/* horz scale of length 0 to 1440 i.e. 00 to 24 hr */}

        {/* plot default session */}
        {/* <div
          style={{
            width: '100%',
            height: '100px',
            marginBottom: '40px',
          }}
          className="testR1"
        >
          <MyTimeline
            dataArr={defaultSessArr}
            dataColor={COLOR_YELLOW}
            emptyColor={COLOR_GRAY}
            tradeColor={COLOR_MAGENTA}
            title="Default Session"
          />
        </div> */}

        {/* plot news session */}
        {/* <div
          style={{
            width: '100%',
            height: '100px',
            marginBottom: '40px',
          }}
        >
          <MyTimeline
            dataArr={newsSessArr}
            dataColor={COLOR_RED}
            emptyColor={COLOR_GRAY}
            tradeColor={COLOR_MAGENTA}
            title="News Session"
          />
        </div> */}

        {/* plot clean session */}
        <div
          style={{
            width: '100%',
            height: '100px',
            marginBottom: '40px',
          }}
        >
          <MyTimeline
            dataArr={substarctedSessionArr}
            dataColor={COLOR_GREEN}
            emptyColor={COLOR_GRAY}
            tradeColor={COLOR_MAGENTA}
            title={title}
            tradesArr={tradesArr}
            showBinarySession={showBinarySession}
            defaultSessArr={defaultSessArr}
            newsSessArr={newsSessArr}
          />
        </div>
      </div>
    </>
  );
};

export default SessionTimeSeries;
