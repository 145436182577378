// session data object
//  news release data is the session object here:
//{ date: dayDate, session_data: [{ name: "USD",star: "1", time: "10:15"}, {name:"JPY:EUR", star:"3", "03:02"}]}

import {
  STAR_1_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
  STAR_2_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
  STAR_3_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
} from '../constants';

// full default session in a day: "0000-0159,0401-0450,0601-1059,1201-1259,1401-1750,1901-2059,2201-2400"

// return deduct news impact time from above default session and return in the above default session format

const DEFAULT_STAR_3_WAIT_BEFORE_AFTER_TIME_MIN = 30;
const DEFAULT_STAR_2_WAIT_BEFORE_AFTER_TIME_MIN = 20;
const DEFAULT_STAR_1_WAIT_BEFORE_AFTER_TIME_MIN = 10;

let STAR_3_WAIT_BEFORE_AFTER_TIME_MIN = 0;
let STAR_2_WAIT_BEFORE_AFTER_TIME_MIN = 0;
let STAR_1_WAIT_BEFORE_AFTER_TIME_MIN = 0;

// old session for 1 hr break between start/end of session for binary trading
// const DEFAULT_SESSION =
//   '0000-0159,0401-0450,0601-1059,1201-1259,1401-1750,1901-2059,2201-2400';

// new session only consider 10 mins break for binary trading
const DEFAULT_SESSION =
  '0000-0250,0310-0450,0510-1150,1210-1250,1310-1350,1410-1750,1810-2150,2210-2400';

//step-1: convert news time to minutes and then convert it to range by adding/substracting constant time based on star
//step-2: then convert default session in minutes to hh:mm
export const getSessionStr = (sessionDataArr) => {
  const getNewsStarWaitingTimeObj = getNewsStarWaitingTime();

  // console.log(getNewsStarWaitingTimeObj);

  STAR_1_WAIT_BEFORE_AFTER_TIME_MIN = getNewsStarWaitingTimeObj.star1;

  STAR_2_WAIT_BEFORE_AFTER_TIME_MIN = getNewsStarWaitingTimeObj.star2;

  STAR_3_WAIT_BEFORE_AFTER_TIME_MIN = getNewsStarWaitingTimeObj.star3;

  // console.log({ sessionDataArr });

  //<-------------- default session ----------------------- >
  let tempDefaultSessionArr = getDefaultSession();

  //<---------------------------------- >

  //<-------------- news session ----------------------- >
  let tempNewsSessionArr = getNewsSession(sessionDataArr);

  // console.log({ tempNewsSessionArr });
  //<------------- ----------------------- >

  //   //<-------------- substract news session from default session----------------------- >
  let { finalCleanSessionTvStr, substarctedSessionArr } =
    substractNewsSessFromDefaultSess(tempNewsSessionArr, tempDefaultSessionArr);

  // console.log({ tempSubstractSessionArr });

  //   //<------------------------------------ >

  //<-------------- gather news sessions----------------------- >
  let finalNewsTvTimeStr = convertSessionToTVFormat(tempNewsSessionArr);

  // console.log({ finalTvTimeRanges });
  //<------------------------------------ >

  return {
    newsSessTvStr: finalNewsTvTimeStr,
    substractedSessTvStr: finalCleanSessionTvStr,
    defaultSessArr: tempDefaultSessionArr,
    newsSessArr: tempNewsSessionArr,
    substarctedSessionArr,
  };
};

//step-2: convert news time to minutes and then convert it to range by adding/substracting constant time based on star
// return: [{ pair: "Usd", end: 1259, start: 1141, time_tv_str: "1901-2059" }, {pair: "Nzd", end: 119, start: 0, time_tv_str: "0000-0159"}]
const getNewsSession = (sessionDataArr) => {
  let tempNewsSessionArr = [];

  sessionDataArr.map((itemObj) => {
    let tempTimeArr = itemObj.time.split(':'); //["03", "02"]

    let tempHour = parseInt(tempTimeArr[0]) * 60;
    let tempMin = parseInt(tempTimeArr[1]);
    let tempTotalTime = tempHour + tempMin;

    let startTime = null;
    let endTime = null;

    if (parseInt(itemObj.star) === 1) {
      startTime = tempTotalTime - STAR_1_WAIT_BEFORE_AFTER_TIME_MIN;
      endTime = tempTotalTime + STAR_1_WAIT_BEFORE_AFTER_TIME_MIN;
    } else if (parseInt(itemObj.star) === 2) {
      startTime = tempTotalTime - STAR_2_WAIT_BEFORE_AFTER_TIME_MIN;
      endTime = tempTotalTime + STAR_2_WAIT_BEFORE_AFTER_TIME_MIN;
    } else if (parseInt(itemObj.star) === 3) {
      startTime = tempTotalTime - STAR_3_WAIT_BEFORE_AFTER_TIME_MIN;
      endTime = tempTotalTime + STAR_3_WAIT_BEFORE_AFTER_TIME_MIN;
    }

    if (startTime < 0) {
      startTime = startTime + 1440;
    }

    if (startTime > 1440) {
      startTime = startTime - 1440;
    }

    if (endTime < 0) {
      endTime = endTime + 1440;
    }

    if (endTime > 1440) {
      endTime = endTime - 1440;
    }

    // convert time to tradingview format like "0012"
    tempNewsSessionArr.push({
      start: startTime,
      end: endTime,
      pair: itemObj.name,
      // trading view time ranges format
      time_tv_str: `${convertTimeToTVFormat(
        parseInt(startTime)
      )}-${convertTimeToTVFormat(parseInt(endTime))}`,
      time: itemObj.time,
      star: itemObj.star,
    });

    // tempNewsSessionArr.push([startTime, endTime]);
  });

  // console.log({ tempNewsSessionArr });

  return tempNewsSessionArr;
};

//step-1: convert default session time hh:mm to in minutes w.r.t midnight 0000,
// return: [{ end: 1259, start: 1141, time_tv_str: "1901-2059" }, { end: 119, start: 0, time_tv_str: "0000-0159"}]
const getDefaultSession = () => {
  // split default session with ","
  const tempDefaultSession = DEFAULT_SESSION.split(',');
  // then convert to object// [{start: 241, end:441}] //minutes from midnight
  let tempDefaultSessionArr = [];
  tempDefaultSession.map((itemStr) => {
    let tempSmallSessionArr = itemStr.split('-'); //["0000", "0159"]
    // start hour
    let tempStartHour = tempSmallSessionArr[0].substring(0, 2);
    tempStartHour = parseInt(tempStartHour) * 60;
    let tempStartMin = parseInt(tempSmallSessionArr[0].substring(2));
    // end hour
    let tempEndHour = tempSmallSessionArr[1].substring(0, 2);
    tempEndHour = parseInt(tempEndHour) * 60;
    let tempEndMin = parseInt(tempSmallSessionArr[1].substring(2));
    tempDefaultSessionArr.push({
      start: tempStartHour + tempStartMin,
      end: tempEndHour + tempEndMin,
      time_tv_str: itemStr,
    });
  });
  // console.log({ tempDefaultSessionArr });

  return tempDefaultSessionArr;
};

//step-3: then substract news range which was in minutes from original default time session which was in minutes
// return: '0401-0450,0601-1059,1201-1259,1401-1750,1901-2059,2201-2400'
const substractNewsSessFromDefaultSess = (
  newsSessionArr,
  defaultSessionArr
) => {
  //convert default sessioon arr to format: [[1141, 1259], []]
  const simpleDefaultSessArr = defaultSessionArr.map((item) => [
    item.start,
    item.end,
  ]);

  //convert news sessioon arr to format: [[1141, 1259], []]
  const simpleNewsSessArr = newsSessionArr.map((item) => [
    item.start,
    item.end,
  ]);

  // substarct news session from default session using algo
  // // return: [[1141, 1259], []]
  const substarctedCleanSessionArr = excludeRange(
    simpleDefaultSessArr,
    simpleNewsSessArr
  );

  // console.log({
  //   simpleDefaultSessArr,
  //   simpleNewsSessArr,
  //   substarctedCleanSessionArr,
  // });

  // convert clean session time to trading view format i.e. 1230-2245,1045-0255
  let finalCleanSessionTvStr = '';

  // convert to object with start and end and get final tv str while gathering all data
  let substarctedSessionArr = substarctedCleanSessionArr.map((item) => {
    if (item[0] || item[1]) {
      const tempFirstTimeStr = convertTimeToTVFormat(parseInt(item[0]));
      const tempSecondTimeStr = convertTimeToTVFormat(parseInt(item[1]));

      const tempTimeStr = `${tempFirstTimeStr}-${tempSecondTimeStr}`;

      finalCleanSessionTvStr +=
        (finalCleanSessionTvStr ? ',' : '') + tempTimeStr;

      return {
        start: item[0],
        end: item[1],
        time_tv_str: tempTimeStr,
      };
    }
  });

  // console.log({ finalCleanSessionTvStr });

  return { finalCleanSessionTvStr, substarctedSessionArr };
};

// exclude one range of arrays of array from another range of arrays of array
// data2: [[1, 7], [9, 10], [12, 14]]
// exclude: [[4, 5], [11, 20]]
// return: [[4, 5], [11, 20]]
// SO: https://stackoverflow.com/a/65434817/5124488
function excludeRange(data2, exclude) {
  let data = [...data2]; // i don't want inplace edit

  exclude.forEach((e) => {
    data.forEach((d, di) => {
      // check intersect
      if (d[0] <= e[1] && e[0] <= d[1]) {
        // split into two range: [Ax, Bx-1] and [By+1, Ay]
        var ranges = [
          [d[0], e[0] - 1],
          [e[1] + 1, d[1]],
        ];
        // keep only valid range where x <= y
        ranges = ranges.filter((k) => k[0] <= k[1]);
        // replace existing range with new ranges
        data.splice(di, 1, ...ranges);
      }
    });
  });
  return data;
}

//step-4: then convert entire substracted session in minutes to hhmm-hhmm,hhmm-hhmm
const convertSessionToTVFormat = (tempSessionArr) => {
  let finalTvTimeRanges = '';

  tempSessionArr.map((item) => {
    if (item.time_tv_str) {
      finalTvTimeRanges += (finalTvTimeRanges ? ',' : '') + item.time_tv_str;
    }
  });

  return finalTvTimeRanges;
};

// convert time to tradingview format i.e. 0130 (convert 90 min to 0130)
const convertTimeToTVFormat = (time_min) => {
  let hours = parseInt(time_min / 60);
  hours = hours >= 10 ? hours : `0${hours}`;
  let min = parseInt(time_min % 60);
  min = min >= 10 ? min : `0${min}`;

  return `${hours}${min}`;
};

export const getNewsStarWaitingTime = () => {
  try {
    let newsStar1 = localStorage.getItem(STAR_1_WAIT_BEFORE_AFTER_TIME_MIN_KEY);
    newsStar1 = parseInt(newsStar1);
    if (isNaN(newsStar1)) {
      saveNewsStarWaitingTime(
        STAR_1_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
        DEFAULT_STAR_1_WAIT_BEFORE_AFTER_TIME_MIN
      );
    }
    newsStar1 = isNaN(newsStar1)
      ? DEFAULT_STAR_1_WAIT_BEFORE_AFTER_TIME_MIN
      : newsStar1;

    let newsStar2 = localStorage.getItem(STAR_2_WAIT_BEFORE_AFTER_TIME_MIN_KEY);
    newsStar2 = parseInt(newsStar2);
    if (isNaN(newsStar2)) {
      saveNewsStarWaitingTime(
        STAR_2_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
        DEFAULT_STAR_2_WAIT_BEFORE_AFTER_TIME_MIN
      );
    }
    newsStar2 = isNaN(newsStar2)
      ? DEFAULT_STAR_2_WAIT_BEFORE_AFTER_TIME_MIN
      : newsStar2;

    let newsStar3 = localStorage.getItem(STAR_3_WAIT_BEFORE_AFTER_TIME_MIN_KEY);
    newsStar3 = parseInt(newsStar3);
    if (isNaN(newsStar3)) {
      saveNewsStarWaitingTime(
        STAR_3_WAIT_BEFORE_AFTER_TIME_MIN_KEY,
        DEFAULT_STAR_3_WAIT_BEFORE_AFTER_TIME_MIN
      );
    }
    newsStar3 = isNaN(newsStar3)
      ? DEFAULT_STAR_3_WAIT_BEFORE_AFTER_TIME_MIN
      : newsStar3;

    return { star1: newsStar1, star2: newsStar2, star3: newsStar3 };
  } catch (err) {
    console.log('Error: ', err.message);
  }

  return {};
};

export const saveNewsStarWaitingTime = (NewsStarKey = '', starTime) => {
  try {
    localStorage.setItem(NewsStarKey, starTime);
  } catch (err) {
    console.log('Error: ', err.message);
  }
};
