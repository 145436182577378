import { useImmer } from 'use-immer';
import { useEffect } from 'react';

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import _ from 'lodash';
import Price from '../../utils/Price';
import getTradeMetaData from '../../utils/getTradeMetaData';

const COLOR_GREEN = '#71ff5e';
const COLOR_RED = '#ff4949';
const COLOR_XAXIS = '#c9d884';
const COLOR_YAXIS = '#8884d8';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const GetTradesMetaData = ({ dataArr }) => {
  return (
    <div>
      {dataArr.map((itemObj, idx) => {
        const Type = itemObj.Type;
        const win_count = itemObj.win_count;
        const loss_count = itemObj.loss_count;
        const refund_count = itemObj.refund_count;
        const total_count = win_count + loss_count;
        const win_ratio = parseInt((win_count * 100) / total_count);

        const pnl = itemObj.PnL;

        return (
          <div
            key={idx}
            style={{
              color: pnl === 0 ? 'white' : pnl > 0 ? COLOR_GREEN : COLOR_RED,
            }}
          >
            {`${(
              ((dataArr[idx] || {}).count * 100) /
              (((dataArr[0] || {}).count || 0) +
                ((dataArr[1] || {}).count || 0))
            ).toFixed(0)}%`}{' '}
            {Type.charAt(0).toUpperCase() + Type.slice(1)}
            {' [Win %: ' +
              win_ratio +
              ' , PnL: ' +
              pnl +
              ', win: ' +
              win_count +
              ', loss: ' +
              loss_count +
              ', total: ' +
              (total_count + refund_count) +
              ' ]'}
          </div>
        );
      })}
    </div>
  );
};
const MyPieChart = ({ tradesArr }) => {
  const [dataArr, setDataArr] = useImmer([]);
  const [winRatio, setWinRatio] = useImmer(0);

  useEffect(() => {
    const { tradeDataArr, winRatio: mWR } = getTradeMetaData(tradesArr);

    setDataArr(tradeDataArr);
    setWinRatio(mWR);
  }, [tradesArr]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const Type = dataArr[index].Type;
    const win_count = dataArr[index].win_count;
    const loss_count = dataArr[index].loss_count;
    const refund_count = dataArr[index].refund_count;
    const total_count = win_count + loss_count;
    const win_ratio = parseInt((win_count * 100) / total_count);

    const pnl = dataArr[index].PnL;

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}{' '}
        {Type.charAt(0).toUpperCase() + Type.slice(1)}
        {' [Win %: ' +
          win_ratio +
          ' , PnL: ' +
          pnl +
          ', win: ' +
          win_count +
          ', loss: ' +
          loss_count +
          ', total: ' +
          (total_count + refund_count) +
          ' ]'}
      </text>
    );
  };

  return (
    <>
      <div style={{ margin: '20px 0 5px' }}>
        <div style={{ marginBottom: '10px' }}>Win Ratio: {winRatio}</div>
        <GetTradesMetaData dataArr={dataArr} />
      </div>

      <ResponsiveContainer width="100%" height="40%" className="testR1">
        <PieChart width={200} height={200}>
          {/* <Legend layout="vertical" verticalAlign="top" align="top" /> */}
          <Pie
            data={dataArr}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={120}
            fill="#8884d8"
            dataKey="count"
          >
            {dataArr.map((entry, index) => {
              // console.log(entry);
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.Type === 'call' ? COLOR_GREEN : COLOR_RED}
                />
              );
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default MyPieChart;
