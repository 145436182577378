import _ from 'lodash';
import Price from './Price';

const getTradeMetaData = (tradesArr) => {
  let assetDataArr = tradesArr;

  assetDataArr = _.sortBy(assetDataArr, (o) =>
    Date.parse(o['Closing Date Time'])
  ).reverse();

  let tradeDataArr = [];
  if (Array.isArray(assetDataArr)) {
    //sort as per time
    tradeDataArr = _(assetDataArr)
      .groupBy('Direction')
      .map((objs, key) => ({
        Type: key,
        count: objs.length,
        PnL: Price(_.sumBy(objs, (item) => Number(item['Net PnL']))),
        win_count: _.filter(objs, (o) => Number(o['Net PnL']) > 0).length,
        loss_count: _.filter(objs, (o) => Number(o['Net PnL']) < 0).length,
        refund_count: _.filter(objs, (o) => Number(o['Net PnL']) === 0).length,
      }))
      .value();
  }

  let winRatio =
    ((((tradeDataArr[0] || {}).win_count || 0) +
      ((tradeDataArr[1] || {}).win_count || 0)) *
      100) /
    (((tradeDataArr[0] || {}).win_count || 0) +
      ((tradeDataArr[0] || {}).loss_count || 0) +
      (((tradeDataArr[1] || {}).win_count || 0) +
        ((tradeDataArr[1] || {}).loss_count || 0)));

  winRatio = Math.round(winRatio);

  return { tradeDataArr, winRatio };
};

export default getTradeMetaData;
