//for auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
// for config
export const SET_CONFIG = 'SET_CONFIG';

// for dashboard
export const SET_V_LEADS = 'SET_V_LEADS';

// route
export const RESET_STATES = 'RESET_STATES';

// for ecom lead
export const SET_ECOM_LEADS = 'SET_ECOM_LEADS';

// for trade
export const SET_TRADE_CONFIG = 'SET_TRADE_CONFIG';

// for image tools hub
export const SET_ITH_REVIEWS = 'SET_ITH_REVIEWS';
