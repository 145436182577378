import axios from 'axios';
import { updateConfig } from '.';
import { SET_ITH_REVIEWS } from '../action-types';

export const getReviews =
  (query = '', callback) =>
  async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      const res = await axios.get(`/api/v1/imagetoolshub/reviews?${query}`);

      const reviews = res.data && res.data.data;
      const total = res.data && res.data.total;
      const limit = res.data && res.data.limit;
      const page = res.data && res.data.page;

      dispatch({
        type: SET_ITH_REVIEWS,
        payload: {
          page,
          reviews,
          total,
          limit,
        },
      });

      callback(true);
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };
