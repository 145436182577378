import axios from 'axios';
import { updateConfig } from '.';
import { SET_V_LEADS } from '../action-types';

export const getVLeads =
  (query = '', selectedCategory, bioSearchText, callback) =>
  async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      const res = await axios.get(`/api/v1/v-leads?${query}`);

      const vLeads = res.data && res.data.data;
      const total = res.data && res.data.total;
      const limit = res.data && res.data.limit;
      const page = res.data && res.data.page;

      dispatch({
        type: SET_V_LEADS,
        payload: {
          page,
          vLeads,
          total,
          limit,
          selectedCategory,
          bioSearchText,
        },
      });

      callback(true);
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };

export const updateVLead =
  (queryObj = {}, callback) =>
  async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      await axios.post('/api/v1/v-leads/update', {
        ...queryObj,
      });

      callback && callback(true);
      dispatch(
        updateConfig({
          loading: false,
          message: 'updated',
        })
      );
    } catch (err) {
      console.log('Error: ', err.message);
      callback && callback(false);
      dispatch(
        updateConfig({
          loading: false,
          message: err?.response?.data?.message || err.message,
        })
      );
    }
  };

export const getInScrapeData =
  (inUrl = '', callback) =>
  async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true, message: '' }));

      const res = await axios.post('/api/v1/v-leads/in-scrape', {
        url: inUrl,
      });

      callback && callback(true, res.data?.data || null);
      dispatch(
        updateConfig({
          loading: false,
        })
      );
    } catch (err) {
      console.log('Error: ', err.message);
      callback && callback(false);
      dispatch(
        updateConfig({
          loading: false,
          message: err?.response?.data?.message || err.message,
        })
      );
    }
  };

export const getSearchSuggestion =
  (bioSearchText, callback) => async (dispatch) => {
    try {
      let suggestionArr = [];

      if ((bioSearchText || '').length >= 3) {
        const res = await axios.post('/api/v1/v-leads/category-suggestion', {
          suggest: bioSearchText,
        });

        if (res.data && Array.isArray(res.data.data)) {
          suggestionArr = res.data.data;
        }
      }

      callback(true, suggestionArr);
    } catch (err) {
      console.log('Error: ', err.message);
      callback(false);

      dispatch(
        updateConfig({
          message: err.response?.data?.message || err.message || 'Error',
          isError: true,
        })
      );
    }
  };
