import {
  Upload,
  message,
  Table,
  Tooltip,
  Button,
  Tag,
  Drawer,
  Calendar,
  Popconfirm,
  Input,
  Modal,
  TimePicker,
  Select,
  Checkbox,
  InputNumber,
} from 'antd';
import Papa from 'papaparse';
import {
  CheckOutlined,
  CopyOutlined,
  DownloadOutlined,
  PlusOutlined,
  RedoOutlined,
  ShareAltOutlined,
  TwitterOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useImmer } from 'use-immer';
import { useEffect, useRef } from 'react';
import moment from 'moment';
import GlobalBarChart from './GlobalBarChart';
import GlobalEquityCurve from './GlobalEquityCurve';
import styled, { createGlobalStyle } from 'styled-components';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';

import { tryParseJSON } from '../../utils/tryParseJSON';
import { useActions } from '../../hooks/useActions';
import MyPieChart from './MyPieChart';
import { TRADE_DRAWER_POSITION, _DEV_ENV_ } from '../../constants';
import ConsecutiveWLBarChart from './ConsecutiveWLBarChart';
import Price from '../../utils/Price';
import _ from 'lodash';
import SessionTimeModal from './SessionTimeModal';
import { getSessionStr } from '../../utils/getSessionStr';
import formatCsvJSONData from '../../utils/formatCsvJSONData';
import getTradeMetaData from '../../utils/getTradeMetaData';

const StyledTable = styled(Table)`
  .ant-table .ant-table-tbody .ant-table-row.table-row-win {
    background: #67e76750;
  }

  .ant-table .ant-table-tbody .ant-table-row.table-row-loss {
    background: #ff767650;
  }
  && tbody > tr:hover > td {
    background: transparent;
  }
  td.ant-table-column-sort {
    background: transparent;
  }
`;
const StyledCalendar = styled(Calendar)`
  &.my-calendar
    .ant-picker-date-panel
    .ant-picker-cell-inner.ant-picker-calendar-date {
    padding: 0;
  }
`;
// global drawer make it dull height
const GlobalDrawerStyle = createGlobalStyle`
&&&.global-drawer.ant-drawer .ant-drawer-content-wrapper {
    height:95% !important
  }
`;

const Journal = (props) => {
  const myDwldImageRef = useRef(null);
  const {
    deleteSelectedDayTrade,
    getTradeCSVJSONString,
    uploadTradeCSVJSONString,
    getYearlyTradeCSVJSONString,
  } = useActions();
  // const [selectedFile, setSelectedFile] = useImmer([]);
  const [uploadClicked, setUploadClicked] = useImmer(false);
  // selected day trade data click on calender
  const [selectedDateTradeDataArr, setSelectedDateTradeDataArr] = useImmer([]);

  const [analyzeTradeDrawer, setAnalyzeTradeDrawer] = useImmer(false);

  const [selectedDate, setSelectedDate] = useImmer('');

  // broker name i.e. IQ Option, Quotex
  const [brokerName, setBrokerName] = useImmer('');

  // broker trade currency
  const [tradeCurrency, setTradeCurrency] = useImmer('');

  // risk amount I took i.e. SL
  const [riskAmt, setRiskAmt] = useImmer(null);

  //open session modal
  const [openSessionModal, setOpenSessionModal] = useImmer();

  // in trade list table
  const [selectedRowKeys, setSelectedRowKeys] = useImmer([]);

  // console.log({ selectedDate });

  // overall net PnL
  const tempNetPnL = Price(
    _.sumBy(selectedDateTradeDataArr, (item) => Number(item['Net PnL']))
  );

  // show trade list in a Table
  const [showTradeList, setShowTradeList] = useImmer(false);
  const [showOpenSessionBtn, setShowOpenSessionBtn] = useImmer(true);

  // entire month data
  const [selectedMonthDataArr, setSelectedMonthDataArr] = useImmer([]);

  const getMonthlyPnLData = (monthlyDataARr) => {
    let noOfTradeDays = 0;
    // no of trades taken in a month
    let noOfTrades = 0;
    Array.isArray(monthlyDataARr) &&
      monthlyDataARr.map((itemObj) => {
        const selectedDayCsvJson =
          (itemObj && tryParseJSON(itemObj.csv_json)) || [];
        const tempNoOfTradeExist =
          (selectedDayCsvJson[0] &&
            Object.keys(selectedDayCsvJson[0]).length > 0 &&
            selectedDayCsvJson.length) ||
          0;

        if (tempNoOfTradeExist > 0) {
          ++noOfTradeDays;
          noOfTrades += tempNoOfTradeExist;
        }
      });

    return { noOfTradeDays, noOfTrades };
  };

  // no. of trade days in a month
  // no of trades taken in a month
  const { noOfTradeDays, noOfTrades } = getMonthlyPnLData(selectedMonthDataArr);

  // entire year data
  const [selectedYearDataArr, setSelectedYearDataArr] = useImmer([]);

  // no. of trade days in a year
  let noOfYearTradeDays = 0;
  // no of trades taken in a year
  let noOfYearTrades = 0;
  Array.isArray(selectedYearDataArr) &&
    selectedYearDataArr.map((itemObj) => {
      const selectedDayCsvJson =
        (itemObj && tryParseJSON(itemObj.csv_json)) || [];
      const tempNoOfTradeExist =
        (selectedDayCsvJson[0] &&
          Object.keys(selectedDayCsvJson[0]).length > 0 &&
          selectedDayCsvJson.length) ||
        0;

      if (tempNoOfTradeExist > 0) {
        ++noOfYearTradeDays;
        noOfYearTrades += tempNoOfTradeExist;
      }
    });
  // console.log({ noOfYearTrades });
  // console.log({ noOfYearTradeDays });
  // entire month net PnL and currency
  // [{price:10, currency: "USD"}, {price:13, currency: "INR"}]
  const [selectedMonthNetPnL, setSelectedMonthNetPnL] = useImmer([]);

  const [selectedYearNetPnL, setSelectedYearNetPnL] = useImmer([]);

  const [selectedMonthWinRatio, setSelectedMonthWinRatio] = useImmer(-1);

  const [selectedYearWinRatio, setSelectedYearWinRatio] = useImmer(-1);

  // console.log({
  //   selectedYearNetPnL,
  //   selectedMonthWinRatio,
  //   selectedYearWinRatio,
  // });

  // get trades for the whole month as default
  useEffect(() => {
    //default is today's date
    getTradeCSVJSONString(moment().format('DD-MM-YYYY'), (data) => {
      setSelectedMonthDataArr(data);
    });
  }, []);

  const getMonthlyPnLPriceCurrencyArr = (monthlyDataArr) => {
    if (!Array.isArray(monthlyDataArr)) {
      return [];
    }
    let tempPnLCurrencyArr = [];

    let tempTotalTrades = 0;
    let tempTotalTradesWin = 0;

    monthlyDataArr.map((itemObj, idx) => {
      let tempDayCsvJsonObj = tryParseJSON(itemObj.csv_json);

      Array.isArray(tempDayCsvJsonObj) &&
        tempDayCsvJsonObj.map((tradeObj, idx2) => {
          // console.log({ tradeObj });

          if (tradeObj) {
            // console.log('data: ', tradeObj['Net PnL'], tradeObj.currency);

            let price = Number(tradeObj['Net PnL'] || 0);
            price = isNaN(price) ? 0 : price;
            const currency = tradeObj.currency || '';

            // total trades
            if (price !== 0) {
              tempTotalTrades += 1;
            }
            // win trades
            if (price > 0) {
              tempTotalTradesWin += 1;
            }

            !!price && tempPnLCurrencyArr.push({ price, currency });
          }
        });
    });

    // console.log({
    //   tempTotalTradesWin,
    //   tempTotalTrades,
    //   win: Math.floor((tempTotalTradesWin * 100) / tempTotalTrades),
    // });

    setSelectedMonthWinRatio(
      Math.floor((tempTotalTradesWin * 100) / tempTotalTrades)
    );

    // final price , currency calculation
    // console.log({ tempPnLCurrencyArr });

    let tempData = _(tempPnLCurrencyArr)
      .groupBy('currency')
      .map((objs, key) => ({
        currency: key,
        price: Price(_.sumBy(objs, (item) => Number(item.price))),
      }))
      .value();

    return tempData;
  };

  // entire month net PnL and currency
  useEffect(() => {
    // console.log({ tempData });
    setSelectedMonthNetPnL(getMonthlyPnLPriceCurrencyArr(selectedMonthDataArr));
  }, [selectedMonthDataArr]);

  // entire year net PnL and currency
  useEffect(() => {
    if (!Array.isArray(selectedYearDataArr)) {
      return;
    }

    let tempPnLCurrencyArr = [];

    let tempTotalTrades = 0;
    let tempTotalTradesWin = 0;

    selectedYearDataArr.map((itemObj, idx) => {
      let tempDayCsvJsonObj = tryParseJSON(itemObj.csv_json);

      Array.isArray(tempDayCsvJsonObj) &&
        tempDayCsvJsonObj.map((tradeObj, idx2) => {
          // console.log({ tradeObj });

          if (tradeObj) {
            // console.log('data: ', tradeObj['Net PnL'], tradeObj.currency);

            let price = Number(tradeObj['Net PnL'] || 0);
            price = isNaN(price) ? 0 : price;

            // total trades
            if (price !== 0) {
              tempTotalTrades += 1;
            }
            // win trades
            if (price > 0) {
              tempTotalTradesWin += 1;
            }

            const currency = tradeObj.currency || '';

            !!price && tempPnLCurrencyArr.push({ price, currency });
          }
        });
    });

    // console.log({
    //   tempTotalTradesWin,
    //   tempTotalTrades,
    //   win: Math.floor((tempTotalTradesWin * 100) / tempTotalTrades),
    // });

    setSelectedYearWinRatio(
      Math.floor((tempTotalTradesWin * 100) / tempTotalTrades)
    );

    // final price , currency calculation
    // console.log({ tempPnLCurrencyArr });

    let tempData = _(tempPnLCurrencyArr)
      .groupBy('currency')
      .map((objs, key) => ({
        currency: key,
        price: Price(_.sumBy(objs, (item) => Number(item.price))),
      }))
      .value();

    // console.log({ tempData });
    setSelectedYearNetPnL(tempData);
  }, [selectedYearDataArr]);

  // update table list of trades after upload is done
  useEffect(() => {
    // after upload get data of selected date
    const tempDateData = getTradeDataOfDate(selectedDate);
    setSelectedDateTradeDataArr(
      (tempDateData && tryParseJSON(tempDateData.csv_json)) || []
    );
  }, [selectedMonthDataArr]);

  const getJSONFromFile = (file) => {
    // Passing file data to parse using Papa.parse
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        let tempData = results.data;

        // format csv json data to common format
        tempData = formatCsvJSONData(tempData, brokerName, tradeCurrency);
        setSelectedDateTradeDataArr(tempData);
      },
    });
  };

  const beforeUpload = (file) => {
    // setSelectedFile([file]);

    const isCSV = file.name.endsWith('.csv');

    if (!isCSV) {
      message.error('You can only load CSV file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 500;
    if (!isLt2M) {
      message.error('CSV File must smaller than 500MB!');
    }
    if (!brokerName || !tradeCurrency || !riskAmt) {
      message.error('Select a broker or trade currency or risk amount!');
      return;
    }

    setUploadClicked(true);
    getJSONFromFile(file);
    return false; //make it false in production, stop uploading, otherwise it throws an error while try to upload
  };

  // upload json string from DB

  const getTradeDataOfDate = (dateStr) => {
    let tempSelectedDateData;
    Array.isArray(selectedMonthDataArr) &&
      selectedMonthDataArr.map((itemObj) => {
        if (moment(itemObj.trade_date).format('DD-MM-YYYY') === dateStr) {
          tempSelectedDateData = itemObj;
        }
      });

    return tempSelectedDateData;
  };

  const dateCellRender = (value) => {
    const currDate = value.format('DD-MM-YYYY');

    const currDayName = value.format('dddd'); //"Sunday", "Monday"

    const selectedDateData = getTradeDataOfDate(currDate);

    // console.log(selectedMonthDataArr);

    const selectedDayCsvJson =
      (selectedDateData && tryParseJSON(selectedDateData.csv_json)) || [];

    // one day net PnL
    const tempEachDayNetPnL = Price(
      _.sumBy(selectedDayCsvJson, (item) => Number(item['Net PnL']))
    );

    // whther at least one trade exist in data arr
    const tempOneTradeExist =
      selectedDayCsvJson[0] && Object.keys(selectedDayCsvJson[0]).length > 0;

    const tempTimeStr = getSessionStr(
      (selectedDateData &&
        tryParseJSON(selectedDateData.session_json).session_data) ||
        []
    ).newsSessTvStr;

    return (
      <div
        onClick={() => {
          setSelectedDate(value.format('DD-MM-YYYY'));
          setSelectedDateTradeDataArr(selectedDayCsvJson);
          setRiskAmt(selectedDateData?.risk);
          setShowTradeList(true);
        }}
        className="testR1"
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          ...((currDayName === 'Saturday' || currDayName === 'Sunday') && {
            background: '#bbcccc95',
          }),
          ...(tempOneTradeExist && { background: '#d9e94d30' }),
        }}
      >
        <div>
          {/* {tempOneTradeExist && <Tag color="green">Update</Tag>} */}
          {!tempOneTradeExist && (
            <Tag color="magenta">
              ➕ {window.innerWidth > 700 && <span>NEW</span>}
            </Tag>
          )}
          {tempTimeStr && (
            <span style={{ width: '2px', height: '2px' }}>📅</span>
          )}
        </div>

        {/* net pnl */}
        {tempEachDayNetPnL ? (
          <div
            style={{
              color:
                tempEachDayNetPnL > 0
                  ? 'green'
                  : tempEachDayNetPnL < 0
                  ? 'red'
                  : 'white',
              fontSize: '12px',
              marginTop: '8px',
            }}
          >
            {window.innerWidth > 700 && <span>PnL:&nbsp;</span>}
            {tempEachDayNetPnL}
            {/* r/r */}
            {selectedDateData && Number(selectedDateData?.risk) > 0 && (
              <div style={{ color: 'purple' }}>
                {' '}
                {Number(
                  parseFloat(
                    tempEachDayNetPnL / Number(selectedDateData?.risk)
                  ).toFixed(1)
                )}{' '}
                R
              </div>
            )}
          </div>
        ) : null}

        {/* total trades */}
        {tempOneTradeExist && !!selectedDayCsvJson.length ? (
          <div
            style={{
              fontSize: '12px',
              // marginTop: '8px',
              // color: 'navy',
              color: '#d6c420',
            }}
          >
            {window.innerWidth > 700 && <span>Trades:&nbsp;</span>}
            {selectedDayCsvJson.length}
          </div>
        ) : null}
      </div>
    );
  };
  const getTradeYearDataOfDate = (monthStr) => {
    let tempSelectedDateData = [];
    Array.isArray(selectedYearDataArr) &&
      selectedYearDataArr.map((itemObj) => {
        if (moment(itemObj.trade_date).format('MM') === monthStr) {
          tempSelectedDateData.push(itemObj);
        }
      });

    return tempSelectedDateData;
  };
  const monthCellRender = (value) => {
    // console.log('month: ', value.format('MM'));
    const currDate = value.format('MM');

    const selectedDateData = getTradeYearDataOfDate(currDate);

    const { noOfTradeDays, noOfTrades } = getMonthlyPnLData(selectedDateData);
    const pnlCurrArr = getMonthlyPnLPriceCurrencyArr(selectedDateData);

    // console.log(currDate, ' monthly report: ', {
    //   noOfTradeDays,
    //   noOfTrades,
    //   pnlCurrArr,
    // });

    return (
      // {/* net pnl and currency for the month , no of trade days in a month*/}
      <div
        style={{
          display: pnlCurrArr.length > 0 ? 'flex' : 'none',
          alignItems: 'start',
          justifyContent: 'start',
          fontSize: '12px',
          height: '100%',
          width: '100%',

          ...(window.innerWidth <= 500 && {
            flexDirection: 'column',
          }),
        }}
        className="testR1"
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {Array.isArray(pnlCurrArr) &&
            pnlCurrArr.map((itemObj, idx) => {
              return (
                <div key={idx}>
                  <span style={{ color: itemObj.price > 0 ? 'green' : 'red' }}>
                    {itemObj.price}
                  </span>
                  &nbsp;
                  <span>{itemObj.currency}</span>
                </div>
              );
            })}
        </div>
        {/* {(!!noOfTradeDays || !!noOfYearTradeDays) && <div> {', '}</div>} */}
        {(!!noOfTradeDays || !!noOfYearTradeDays) && (
          <div style={{ color: 'teal', marginLeft: '12px' }}>
            {noOfTradeDays || noOfYearTradeDays} Trading Days
          </div>
        )}
        {/* {(!!noOfTrades || !!noOfYearTrades) && <div> {', '}</div>} */}
        {(!!noOfTrades || !!noOfYearTrades) && (
          <div style={{ color: '#d6c420', marginLeft: '12px' }}>
            {noOfTrades || noOfYearTrades} Trades
          </div>
        )}
      </div>
    );
  };

  // const monthCellRender = (value) => {
  //   // console.log({ month: value.month(), year: value.year(), day: value.day() });

  //   const num = [];
  //   //  const num = getMonthData(value);
  //   return num ? (
  //     <div className="notes-month">
  //       <section>{num}</section>
  //       <span>Backlog number</span>
  //     </div>
  //   ) : null;
  // };

  const onPanelChange = (value, mode) => {
    // console.log(value.format('DD-MM-YYYY'), mode);
    if (mode === 'month') {
      getTradeCSVJSONString(value.format('DD-MM-YYYY'), (data) => {
        setSelectedMonthDataArr(data);
        setSelectedYearDataArr([]);
        setSelectedYearWinRatio(-1);
      });

      setShowOpenSessionBtn(true);
    }

    if (mode === 'year') {
      getYearlyTradeCSVJSONString(value.format('DD-MM-YYYY'), (data) => {
        setSelectedYearDataArr(data);
        setSelectedMonthDataArr([]);
        setSelectedMonthWinRatio(-1);
      });

      setShowOpenSessionBtn(false);
    }
  };

  const getPnLForOneDay = (tempPnLCurrencyArr) => {
    // console.log(tempPnLCurrencyArr);
    let tempArr = _(tempPnLCurrencyArr)
      .groupBy('currency')
      .map((objs, key) => ({
        currency: key,
        price: Price(
          _.sumBy(objs, (item) => {
            let price = Number(item['Net PnL'] || 0);
            price = isNaN(price) ? 0 : price;
            return Number(price);
          })
        ),
      }))
      .value();

    let finalStr = '';
    let pnlNumber = 0; //sum of prices

    tempArr.map((itemObj, idx) => {
      finalStr += `${itemObj.price} ${itemObj.currency}${
        idx < tempArr.length - 1 ? ', ' : ''
      }`;
      pnlNumber += itemObj.price;
    });
    console.log({ finalStr });

    return { pnlStr: finalStr, pnl: pnlNumber };
  };

  // for trade list trades review list for TV
  const copyTradeListForReviewsTV = (tradesArr) => {
    // format is opening_time#opening_price#closing_time#closing_price#direction_u_for_up_d_for_down, ...
    // console.log(tradesArr);

    let finalAllTradeStr = '';

    tradesArr.map((tradeObj, idx) => {
      const openingTime = moment(tradeObj['Opening Date Time'])
        .local()
        .format('HH:mm:ss');

      const closingTime = moment(tradeObj['Closing Date Time'])
        .local()
        .format('HH:mm:ss');

      const openingPrice = tradeObj['Opening price'];
      const closingPrice = tradeObj['Closing price'];

      const direction =
        tradeObj['Direction'].toLowerCase() === 'call' ? 'u' : 'd';

      let finalStr = `${openingTime}#${openingPrice}#${closingTime}#${closingPrice}#${direction}`;

      if (idx < tradesArr.length - 1) {
        finalStr = finalStr + ',';
      }

      finalAllTradeStr = finalAllTradeStr + finalStr;

      // console.log({
      //   openingTime,
      //   closingTime,
      //   openingPrice,
      //   closingPrice,
      //   direction,
      //   finalStr,
      // });
    });

    // console.log({ finalAllTradeStr });

    navigator.clipboard && navigator.clipboard.writeText(finalAllTradeStr);
  };

  return (
    <div style={{ padding: '3%' }}>
      {/* for global drawer style*/}
      <GlobalDrawerStyle />
      {/* session modal */}
      {/* session modal */}
      <div style={{}}>
        {openSessionModal && (
          <SessionTimeModal
            openSessionModal={openSessionModal}
            setOpenSessionModal={setOpenSessionModal}
            localSelectedDate={selectedDate} //local date from antd
            selectedDateData={getTradeDataOfDate(selectedDate)}
            tradesArr={selectedDateTradeDataArr}
          />
        )}
      </div>
      {analyzeTradeDrawer && (
        <Drawer
          title="Trade Analyzer"
          placement={TRADE_DRAWER_POSITION}
          closable={true}
          onClose={() => setAnalyzeTradeDrawer(false)}
          open={analyzeTradeDrawer}
          key={TRADE_DRAWER_POSITION + 'random_key123'}
          size={'large'}
          className="global-drawer"
        >
          {/* Overall Bar Chart */}
          <GlobalBarChart tradesArr={selectedDateTradeDataArr} />
          <hr />
          {/* Overall  Consecutive Win/Loss*/}
          <div>Overall Consecutive Win/Loss Chart</div>
          <ConsecutiveWLBarChart tradesArr={selectedDateTradeDataArr} />
          <hr />
          {/* Overall Equity Curve */}
          <GlobalEquityCurve tradesArr={selectedDateTradeDataArr} />
          <hr />
          {/* Overall Pie Chart */}
          <div>
            Overall Pie Chart: Call/Put, Total:{' '}
            {selectedDateTradeDataArr.length}
          </div>
          <MyPieChart tradesArr={selectedDateTradeDataArr} />
        </Drawer>
      )}

      {/* drawer for showing list of trades */}

      {showTradeList && (
        <Drawer
          title="Trade List"
          placement={TRADE_DRAWER_POSITION}
          closable={true}
          onClose={() => {
            setShowTradeList(false);
            setUploadClicked(false);
            // get json string from DB
            selectedDate &&
              getTradeCSVJSONString(selectedDate, (data) => {
                data && setSelectedMonthDataArr(data);
              });
          }}
          open={showTradeList}
          key={TRADE_DRAWER_POSITION}
          size={'large'}
          className="global-drawer"
        >
          {/* Overall Trade list in the Table */}
          <div style={{ display: 'flex' }}>
            <div>
              <div style={{ marginBottom: '12px' }}>
                <div>Upload Trade</div>
                <Tag color="green">{selectedDate}</Tag>
              </div>

              {/* Select Logs */}
              {uploadClicked ? (
                <span style={{ margin: '8px 0 8px 0', color: 'green' }}>
                  <CheckOutlined style={{ marginRight: '12px' }} />
                  File Selected
                </span>
              ) : (
                <Upload
                  listType="picture-card"
                  fileList={[]}
                  beforeUpload={beforeUpload}
                  // onRemove={() => {
                  //   if (!selectedDate) {
                  //     return;
                  //   }
                  //   // setSelectedFile([]);
                  //   // setSelectedDateTradeDataArr([]);
                  // }}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Choose File</div>
                  </div>
                </Upload>
              )}
              {/* upload csv button */}
              <div>
                {/* select broker name */}
                <div style={{ marginBottom: '12px' }}>
                  {/* select a broker */}
                  <Select
                    defaultValue=""
                    style={{
                      width: 120,
                    }}
                    onChange={(val) => {
                      // console.log('data: ', val);
                      setBrokerName(val);
                    }}
                    options={[
                      {
                        value: 'Quotex',
                        label: 'Quotex',
                      },
                      {
                        value: 'IQ Option',
                        label: 'IQ Option',
                      },
                    ]}
                  />
                  {/* select a currency */}
                  <Select
                    defaultValue=""
                    style={{
                      width: 120,
                      marginLeft: 12,
                      marginRight: 12,
                    }}
                    onChange={(val) => {
                      // console.log('data: ', val);
                      setTradeCurrency(val);
                    }}
                    options={[
                      {
                        value: 'USD',
                        label: 'USD',
                      },
                      {
                        value: 'INR',
                        label: 'INR',
                      },
                    ]}
                  />
                  {/* risk amount */}
                  <InputNumber
                    defaultValue=""
                    // value={riskAmt}
                    style={{
                      width: 120,
                      marginTop: 12,
                    }}
                    onChange={(val) => {
                      // console.log('data: ', val);
                      setRiskAmt(val);
                    }}
                    min={1}
                    step="0.1"
                  />
                </div>
                <Button
                  onClick={() => {
                    if (!selectedDateTradeDataArr) {
                      message.error('Select a CSV file!');
                      return;
                    }

                    uploadTradeCSVJSONString(
                      selectedDate,
                      selectedDateTradeDataArr,
                      '', //session json string
                      false, //whether to fetch news from remote website
                      (getTradeDataOfDate(selectedDate) || {}).id || null, //trade id , since trade doesn't exist,
                      riskAmt, //how much I risked
                      () => {
                        setUploadClicked(false);
                      }
                    );
                  }}
                  style={{ margin: '12px 0' }}
                  type="primary"
                  disabled={!uploadClicked}
                >
                  Upload Trade
                </Button>
              </div>
            </div>
            {/* Analyze Trade button */}
            <div
              style={{
                marginLeft: '22px',
                display: 'flex',
                flexDirection: 'column',
              }}
              className="testR1"
            >
              <div style={{ marginLeft: '22px', display: 'flex' }}>
                <Button
                  onClick={() => {
                    // analyzeCSVNow();
                    setAnalyzeTradeDrawer(!analyzeTradeDrawer);
                  }}
                  style={{ marginRight: '12px' }}
                  type="primary"
                >
                  Analyze Trade
                </Button>

                {/* Delete Trade button */}
                <div style={{ marginLeft: '22px' }}>
                  <Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={() => {
                      selectedDate && deleteSelectedDayTrade(selectedDate);
                    }}
                    onCancel={undefined}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button style={{ marginRight: '12px' }} type="danger">
                      Delete Trade
                    </Button>
                  </Popconfirm>
                </div>
              </div>

              {/* Session Data */}
              <div style={{ marginLeft: '22px', marginTop: '20px' }}>
                <span>
                  {/* btn to copy TV session formatted session str for trading view script */}

                  <Button
                    onClick={() => {
                      setOpenSessionModal(!openSessionModal);
                    }}
                    style={{ margin: '12px 0', background: '#00c3ff' }}
                    type="primary"
                    // disabled={!uploadClicked}
                  >
                    Open Session
                  </Button>
                </span>
              </div>
            </div>
          </div>

          <>
            {/* download image */}
            <div
              style={{
                marginTop: '22px',
                ...(Array.isArray(selectedDateTradeDataArr) &&
                  selectedDateTradeDataArr[0] &&
                  Object.keys(selectedDateTradeDataArr[0]).length <= 0 && {
                    display: 'none',
                  }),
              }}
            >
              {/* download image */}
              <Tooltip title="Download Image">
                <DownloadOutlined
                  style={{ fontSize: '22px' }}
                  onClick={async () => {
                    if (!myDwldImageRef.current) {
                      return;
                    }

                    const canvas = await html2canvas(myDwldImageRef.current);
                    const dataURL = canvas.toDataURL('image/png');

                    downloadjs(
                      dataURL,
                      `${selectedDate}-trade-data-download.png`,
                      'image/png'
                    );
                  }}
                />
              </Tooltip>
              {/* copy image */}
              <Tooltip title="Copy Image">
                <CopyOutlined
                  style={{ fontSize: '22px', marginLeft: '28px' }}
                  onClick={async () => {
                    if (!myDwldImageRef.current) {
                      return;
                    }

                    const canvas = await html2canvas(myDwldImageRef.current);
                    canvas.toBlob((blob) =>
                      navigator.clipboard.write([
                        new ClipboardItem({ 'image/png': blob }),
                      ])
                    );
                  }}
                />
              </Tooltip>
            </div>
            <div
              style={{ padding: '10px' }}
              ref={myDwldImageRef}
              className="testR1"
            >
              <hr />
              <>
                {Array.isArray(selectedDateTradeDataArr) &&
                  selectedDateTradeDataArr[0] &&
                  Object.keys(selectedDateTradeDataArr[0]).length > 0 && (
                    // {/* total logs */}

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection:
                          window.innerWidth <= 500 ? 'column' : 'row',
                      }}
                    >
                      <div>
                        <span
                          style={{
                            color: '#dbbd15',
                            fontSize:
                              window.innerWidth <= 500 ? '16px' : '22px',
                          }}
                        >
                          Total Trades: {selectedDateTradeDataArr.length}
                        </span>
                        {','}
                        <span
                          style={{
                            color:
                              tempNetPnL > 0
                                ? 'green'
                                : tempNetPnL < 0
                                ? 'red'
                                : 'white',
                            fontSize:
                              window.innerWidth <= 500 ? '16px' : '22px',
                          }}
                        >
                          &nbsp;Net PnL:&nbsp;
                          {/* {tempNetPnL} */}
                          {getPnLForOneDay(selectedDateTradeDataArr).pnlStr}
                        </span>
                        <span>,</span>
                        <span
                          style={{
                            color: 'navy',
                            marginLeft: '10px',
                            fontSize:
                              window.innerWidth <= 500 ? '16px' : '22px',
                          }}
                        >
                          Win Ratio:{' '}
                          {getTradeMetaData(selectedDateTradeDataArr).winRatio}{' '}
                          {' %'}
                        </span>
                        {Number(riskAmt) > 0 && <span>{','}</span>}
                        {/* risk/reward */}
                        {Number(riskAmt) > 0 && (
                          <span
                            style={{
                              color: 'purple',
                              marginLeft: '10px',
                              fontSize:
                                window.innerWidth <= 500 ? '16px' : '22px',
                            }}
                          >
                            {Number(
                              parseFloat(
                                getPnLForOneDay(selectedDateTradeDataArr).pnl /
                                  Number(riskAmt)
                              ).toFixed(1)
                            )}{' '}
                            R
                          </span>
                        )}
                      </div>
                    </div>
                  )}
              </>
              <hr />
              {/* Overall Equity Curve */}
              <div style={{ marginTop: '20px' }}>
                <GlobalEquityCurve tradesArr={selectedDateTradeDataArr} />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '14px',
                  textAlign: 'right',
                }}
                className="testR1"
              >
                {/* twitter image */}
                <div>
                  <span
                    style={{
                      marginRight: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="testR1"
                  >
                    <TwitterOutlined
                      style={{
                        fontSize: '20px',
                        marginRight: '2px',
                        color: '#0696e9',
                      }}
                      className="testR1"
                    />
                    <span
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      @BlueSealTrader
                    </span>
                  </span>
                </div>
                <div> {selectedDate}</div>
              </div>
            </div>
            {/* Overall Pie Chart */}
            <hr />
            <div>
              Overall Pie Chart: Call/Put, Total:{' '}
              {selectedDateTradeDataArr.length}
            </div>
            <MyPieChart tradesArr={selectedDateTradeDataArr} />
          </>
          <hr />
          {/* Copy Trades for Review for TV */}
          <div
            style={{
              margin: '22px 0',
              ...((!navigator.clipboard ||
                (Array.isArray(selectedDateTradeDataArr) &&
                  selectedDateTradeDataArr[0] &&
                  Object.keys(selectedDateTradeDataArr[0]).length <= 0)) && {
                display: 'none',
              }),
            }}
          >
            <Button
              onClick={async () => {
                copyTradeListForReviewsTV(selectedDateTradeDataArr);
              }}
              type="primary"
            >
              Copy All Trades for Review on TV
            </Button>
          </div>
          {/* logs Table */}
          {Array.isArray(selectedDateTradeDataArr) &&
            selectedDateTradeDataArr[0] &&
            Object.keys(selectedDateTradeDataArr[0]).length > 0 && (
              <div>
                {/* total logs */}

                <StyledTable
                  columns={[
                    {
                      title: 'Asset',
                      dataIndex: 'Asset',
                      key: 'Asset',
                      render: (text = '', record) => (
                        <span
                          onClick={() => {
                            setSelectedRowKeys([]);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {text}
                        </span>
                      ),
                      sorter: (a, b) =>
                        `${a['Asset']}`.localeCompare(`${b['Asset']}`),
                    },
                    ...COLUMNS,
                  ]}
                  dataSource={selectedDateTradeDataArr}
                  rowKey="Position ID"
                  style={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                  }}
                  pagination={false}
                  size="large"
                  showSorterTooltip={false}
                  rowClassName={(record, index) =>
                    Number(record['Net PnL']) > 0
                      ? 'table-row-win'
                      : Number(record['Net PnL']) < 0
                      ? 'table-row-loss'
                      : ''
                  }
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                      setSelectedRowKeys(selectedRowKeys);
                      // console.log('Selected rows:', selectedRows);
                      copyTradeListForReviewsTV(selectedRows);
                    },
                  }}
                  // scroll={{ x: 950, y: 'calc(100vh - 220px)' }}
                />
              </div>
            )}
        </Drawer>
      )}

      {/* net pnl and currency for the month , no of trade days in a month*/}
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'start',
          // flexDirection: 'column',
          fontSize: '22px',
        }}
        className="testR1"
      >
        {(selectedMonthNetPnL.length > 0 || selectedYearNetPnL.length > 0) && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {Array.isArray(
                selectedMonthNetPnL.length > 0
                  ? selectedMonthNetPnL
                  : selectedYearNetPnL.length > 0
                  ? selectedYearNetPnL
                  : []
              ) &&
                (selectedMonthNetPnL.length > 0
                  ? selectedMonthNetPnL
                  : selectedYearNetPnL.length > 0
                  ? selectedYearNetPnL
                  : []
                ).map((itemObj, idx) => {
                  return (
                    <div key={idx}>
                      <span
                        style={{ color: itemObj.price > 0 ? 'green' : 'red' }}
                      >
                        {itemObj.price}
                      </span>
                      &nbsp;
                      <span>{itemObj.currency}</span>
                    </div>
                  );
                })}
            </div>
            {/* {(!!noOfTradeDays || !!noOfYearTradeDays) && <div> {', '}</div>} */}
            {(!!noOfTradeDays || !!noOfYearTradeDays) && (
              <div style={{ color: 'teal', marginLeft: '12px' }}>
                {noOfTradeDays || noOfYearTradeDays} Trading Days
              </div>
            )}
            {/* {(!!noOfTrades || !!noOfYearTrades) && <div> {', '}</div>} */}
            {(!!noOfTrades || !!noOfYearTrades) && (
              <div style={{ color: '#d6c420', marginLeft: '12px' }}>
                {noOfTrades || noOfYearTrades} Trades
              </div>
            )}
            {/* win ratio */}
            {(selectedMonthWinRatio >= 0 || selectedYearWinRatio >= 0) && (
              <div style={{ color: '#a220d6', marginLeft: '12px' }}>
                {selectedMonthWinRatio >= 0
                  ? selectedMonthWinRatio
                  : selectedYearWinRatio >= 0
                  ? selectedYearWinRatio
                  : ''}{' '}
                % Win
              </div>
            )}
          </>
        )}
      </div>
      {
        <div>
          <Button
            onClick={() => {
              setSelectedDate(moment().format('DD-MM-YYYY'));

              setOpenSessionModal(!openSessionModal);
            }}
            style={{
              margin: '12px 0',
              background: showOpenSessionBtn ? '#00c3ff' : '',
            }}
            type={'primary'}
            size="small"
            disabled={!showOpenSessionBtn}
          >
            Open Session
          </Button>
        </div>
      }

      {/* Calender */}
      <div>
        <StyledCalendar
          // onChange={onCalenderChange}
          dateCellRender={dateCellRender}
          // monthCellRender={monthCellRender}
          onPanelChange={onPanelChange}
          // dateFullCellRender={dateFullCellRender}
          monthCellRender={monthCellRender}
          className="my-calendar"
        />
      </div>
    </div>
  );
};

export default Journal;

const COLUMNS = [
  // {
  //   title: '#',
  //   dataIndex: '',
  //   key: '',
  //   render: (text = '', record) => (
  //     <span>
  //       <Checkbox onChange={() => {}}></Checkbox>
  //     </span>
  //   ),
  // },
  // {
  //   title: 'Asset',
  //   dataIndex: 'Asset',
  //   key: 'Asset',
  //   sorter: (a, b) => `${a['Asset']}`.localeCompare(`${b['Asset']}`),
  // },
  {
    title: 'Direction',
    dataIndex: 'Direction',
    key: 'Direction',
    render: (text = '', record) => (
      <span>
        {text.toLowerCase() === 'call' && (
          <span>
            <Tag color="#87d068">Call</Tag>
          </span>
        )}
        {text.toLowerCase() === 'put' && (
          <span>
            <Tag color="#f50">Put</Tag>
          </span>
        )}
      </span>
    ),
    sorter: (a, b) => `${a['Direction']}`.localeCompare(`${b['Direction']}`),
  },
  {
    title: 'Investments',
    dataIndex: 'Investments',
    key: 'Investments',
    sorter: (a, b) => a['Investments'] - b['Investments'],
  },

  {
    title: 'Net PnL',
    dataIndex: 'Net PnL',
    key: 'Net PnL',
    sorter: (a, b) => a['Net PnL'] - b['Net PnL'],
  },

  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    sorter: (a, b) => a['currency'] - b['currency'],
  },

  {
    title: 'Opening Date Time',
    dataIndex: 'Opening Date Time',
    key: 'Opening Date Time',
    render: (text = '', record) => (
      <Tooltip
        title={`${moment(text).fromNow(true)} ago`}
        placement="top"
        // overlayStyle={{ background: 'white' }}
      >
        <span
          style={{
            visibility: !text && 'hidden',
            // color:
            //   Number(record['Net PnL']) > 0
            //     ? 'green'
            //     : Number(record['Net PnL']) < 0
            //     ? 'red'
            //     : 'white',
          }}
        >
          {moment(text).local().format('DD-MM-YYYY HH:mm:ss')}
        </span>
      </Tooltip>
    ),
    sorter: (a, b) =>
      `${a['Opening Date Time']}`.localeCompare(`${b['Opening Date Time']}`),
    defaultSortOrder: 'descend',
  },

  {
    title: 'Opening price',
    dataIndex: 'Opening price',
    key: 'Opening price',
    render: (text = '', record) => (
      <span
        onClick={() => {
          navigator.clipboard && navigator.clipboard.writeText(text);
        }}
        style={{ ...(navigator.clipboard && { cursor: 'pointer' }) }}
      >
        {text}
        <CopyOutlined style={{ marginLeft: '4px' }} />
      </span>
    ),
    sorter: (a, b) =>
      `${a['Opening price']}`.localeCompare(`${b['Opening price']}`),
  },
  {
    title: 'Closing Date Time',
    dataIndex: 'Closing Date Time',
    key: 'Closing Date Time',
    render: (text = '', record) => (
      <span style={{ visibility: !text && 'hidden' }}>
        {moment(text).local().format('DD-MM-YYYY HH:mm:ss')}
      </span>
    ),
    sorter: (a, b) =>
      a['Closing Date Time'].localeCompare(b['Closing Date Time']),
  },
  {
    title: 'Closing price',
    dataIndex: 'Closing price',
    key: 'Closing price',
    sorter: (a, b) => a['Closing price'] - b['Closing price'],
  },
  {
    title: 'Broker',
    dataIndex: 'broker',
    key: 'broker',
    sorter: (a, b) => a['broker'] - b['broker'],
  },
  {
    title: 'Broker Data',
    dataIndex: 'broker_data',
    key: 'broker_data',
    render: (text = '', record) => (
      <Tooltip
        title={JSON.stringify(text)}
        placement="top"
        // overlayStyle={{ background: 'white' }}
      >
        <span style={{ visibility: !text && 'hidden' }}>
          {`${JSON.stringify(text)}`.length > 5 &&
            `${JSON.stringify(text).substring(0, 5)}..`}
        </span>
      </Tooltip>
    ),
    sorter: (a, b) => a['broker_data'] - b['broker_data'],
  },

  {
    title: 'Position ID',
    dataIndex: 'Position ID',
    key: 'Position ID',
    render: (text, record) => (
      <Tooltip title={`${text}`} placement="top">
        <span style={{ visibility: !text && 'hidden' }}>
          {`${JSON.stringify(text)}`.length > 5 &&
            `${JSON.stringify(text).substring(1, 4)}..`}
        </span>
      </Tooltip>
    ),
    sorter: (a, b) =>
      `${a['Position ID']}`.localeCompare(`${b['Position ID']}`),
  },
];

// // copy for tv, time stamp
// const [copyTimeStamp, setCopyTimeStamp] = useImmer('');

// // copy for tv, opening price
// const [copyOpeningPrice, setCopyOpeningPrice] = useImmer('');

// // copy for tv, trade direction
// const [copyTradeDirection, setCopyTradeDirection] = useImmer('');

// // copy for tv, net pnl
// const [copyNetPnL, setCopyNetPnL] = useImmer('');

// const copyToClipboardForTV = (tradesArr) => {
//   // format data to copy later
//   let tempCopyTimeStamp = [];
//   let tempCopyOpeningPrice = [];
//   let tempCopyTradeDirection = [];
//   let tempCopyNetPnL = [];

//   tradesArr.map((positionObj) => {
//     // rounddown time
//     const tempTime = moment(
//       new Date(positionObj['Opening Date Time']).getTime()
//     )
//       .startOf('minute')
//       .toDate()
//       .getTime();

//     // console.log(tempTime);

//     tempCopyTimeStamp.push(tempTime);

//     tempCopyOpeningPrice.push(positionObj['Opening price']);
//     tempCopyTradeDirection.push('' + positionObj['Direction']);
//     tempCopyNetPnL.push(Price(positionObj['Net PnL']));
//   });

//   setCopyTimeStamp(tempCopyTimeStamp);
//   setCopyOpeningPrice(tempCopyOpeningPrice);
//   setCopyTradeDirection(tempCopyTradeDirection);
//   setCopyNetPnL(tempCopyNetPnL);
// };
//  {
//    /* copy text */
//  }
//  {
//    /* <div> */
//  }
//  {
//    /* copy for tv, time stamp */
//  }
//  {
//    /* <div style={{ marginLeft: '22px' }}>
//           <Button
//             onClick={() => {
//               // copy
//               navigator.clipboard.writeText(copyTimeStamp);
//             }}
//             style={{ marginRight: '12px', marginBottom: '12px' }}
//             type="primary"
//           >
//             Copy TimeStamp
//           </Button>
//         </div> */
//  }

//  {
//    /* copy for tv, opening price */
//  }
//  {
//    /* <div style={{ marginLeft: '22px' }}>
//           <Button
//             onClick={() => {
//               // copy
//               navigator.clipboard.writeText(copyOpeningPrice);
//             }}
//             style={{ marginRight: '12px', marginBottom: '12px' }}
//             type="primary"
//           >
//             Copy opening price
//           </Button>
//         </div> */
//  }

//  {
//    /* copy for tv, trade direction */
//  }
//  {
//    /* <div style={{ marginLeft: '22px' }}>
//           <Button
//             onClick={() => {
//               // copy
//               navigator.clipboard.writeText(
//                 JSON.stringify(copyTradeDirection)
//               );
//             }}
//             style={{ marginRight: '12px', marginBottom: '12px' }}
//             type="primary"
//           >
//             Copy trade direction
//           </Button>
//         </div> */
//  }

//  {
//    /* copy for tv, net pnl */
//  }
//  {
//    /* <div style={{ marginLeft: '22px' }}>
//           <Button
//             onClick={() => {
//               // copy
//               navigator.clipboard.writeText(copyNetPnL);
//             }}
//             style={{ marginRight: '12px', marginBottom: '12px' }}
//             type="primary"
//           >
//             Copy net pnl
//           </Button>
//         </div> */
//  }
//  {
//    /* </div> */
//  }
