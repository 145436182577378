import {
  Pagination,
  Table,
  Select,
  Tag,
  Tooltip,
  Button,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { useActions } from '../../hooks/useActions';
import isEmpty from '../../validation/is-empty';
import MailContentModal from './MailContentModal';
import { RedoOutlined } from '@ant-design/icons';
import SearchBox from './SearchBox';

const { Option } = Select;

const Dashboard = (props) => {
  const [mailModalVisible, setMailModalVisible] = useImmer(false);
  const [selectedRow, setSelectedRow] = useImmer({});
  const [sortByObj, setSortByObj] = useImmer(sortByArr[0]);

  const { getVLeads, updateVLead } = useActions();
  const { page, vLeads, total, limit, selectedCategory, bioSearchText } =
    useSelector((state) => state.dashboard);

  useEffect(() => {
    //get leads
    getLeads();
  }, [sortByObj]);

  const getLeads = (
    mPage = page,
    mLimit = limit,
    dateFrom = undefined,
    dateTo = undefined,
    callback
  ) => {
    const query = `page=${mPage}&limit=${mLimit}&sort=${
      sortByObj.sort
    }&filter=${
      dateFrom && dateTo ? `dateFrom:${dateFrom},dateTo:${dateTo}` : ''
    }${bioSearchText ? `,bio:${encodeURIComponent(bioSearchText || '')}` : ''}${
      selectedCategory
        ? `,category:${encodeURIComponent(selectedCategory || '')}`
        : ''
    }`;

    getVLeads(query, selectedCategory, bioSearchText, (success) => {
      success && callback && callback();
    });
  };

  const updateVLeadNow = (fieldsObj = {}, callback) => {
    updateVLead(
      {
        ...fieldsObj,
      },
      (success) => {
        success && getLeads();
        success && callback && callback(true);
      }
    );
  };

  const updateModalMailContent = (mailContent, callback) => {
    updateVLeadNow(mailContent, callback);
  };

  // for sort by select, index starts from 0
  const onSortByChange = (index) => {
    console.log(index, sortByArr[index].sort);

    setSortByObj(sortByArr[index]);
  };

  return (
    <div style={{ padding: '3%' }}>
      {/* mail modal */}
      {mailModalVisible && (
        <MailContentModal
          modalVisible={mailModalVisible}
          handleModalVisibility={(isVisible) => setMailModalVisible(isVisible)}
          userObj={selectedRow}
          updateMailContent={updateModalMailContent}
          getLeads={getLeads}
        />
      )}

      {/* total leads */}
      <div style={{ marginBottom: '12px' }}>
        <span> Total leads: {total}</span>

        {/* re-fetch v-leads */}
        <span
          onClick={() => getLeads(page, limit)}
          style={{ cursor: 'pointer', marginLeft: '12px' }}
        >
          <Tooltip title="re-fetch V Leads">
            <RedoOutlined />
          </Tooltip>
        </span>
      </div>
      {/* pagination */}
      <div style={{ display: 'flex', margin: '0 0 48px 0' }}>
        <Pagination
          current={page}
          total={total}
          pageSize={limit}
          onChange={(page, pageSize) => {
            getLeads(page, pageSize);
          }}
          showSizeChanger={true}
          style={{ fontSize: '16px' }}
          showQuickJumper
        />
      </div>

      {/* search in bio or category */}
      <div style={{ marginBottom: '24px', maxWidth: '700px' }}>
        <SearchBox />
      </div>

      {/* sort by & Date range */}
      <div style={{ display: 'flex' }}>
        {/* sort by */}
        <div
          className="testR1"
          style={{
            height: 'auto',
            fontWeight: 'bold',
            fontSize: '16px',
            marginRight: '24px',
          }}
        >
          Sort by:&nbsp;
          <Select
            value={sortByObj ? sortByObj.type : null}
            style={{ width: 240 }}
            onChange={onSortByChange}
          >
            {Array.isArray(sortByArr) &&
              sortByArr.map((itemObj, index) => (
                <Option key={index} value={index}>
                  {itemObj.type}
                </Option>
              ))}
          </Select>
        </div>

        {/* Date range */}
        <div>
          <DatePicker.RangePicker
            onChange={(value, dateString) => {
              console.log(dateString);
              getLeads(undefined, undefined, dateString[0], dateString[1]);
            }}
          />
        </div>
      </div>

      {/* v leads table */}
      <Table
        columns={[
          {
            title: 'Mail Content',
            dataIndex: 'mail_content',
            key: 'mail_content',
            render: (text, record) => (
              <>
                <Button
                  onClick={() => {
                    setMailModalVisible(true);
                    setSelectedRow(record);
                  }}
                  type={
                    isEmpty(text)
                      ? 'default'
                      : !!record.mail_send
                      ? 'primary'
                      : 'danger'
                  }
                >
                  Show Mail
                </Button>
              </>
            ),
          },
          {
            title: 'Mail Ready',
            dataIndex: 'mail_ready',
            key: 'mail_ready',
            render: (text, record) => (
              <div
                style={{
                  margin: '2px 2px 16px 2px',
                  width: '130px',
                  height: '20px',
                }}
              >
                <Select
                  value={text ? 'TRUE' : 'FALSE'}
                  style={{ width: '100%', color: text ? 'red' : 'grey' }}
                  onChange={(val) => {
                    updateVLeadNow({
                      id: record.id,
                      mail_ready: val.toLowerCase() === 'true',
                    });
                  }}
                  disabled={
                    !!record.mail_unsubscribed ||
                    !!record.mail_send ||
                    !!record.mail_disabled
                  }
                >
                  {['TRUE', 'FALSE'].map((status, index) => {
                    return (
                      <Option key={index} value={status}>
                        {status}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ),
          },
          ...COLUMNS,
        ]}
        dataSource={vLeads}
        rowKey="id"
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        pagination={false}
        size="large"
        showSorterTooltip={true}
        locale={{
          emptyText: !Array.isArray(vLeads) && (
            <div style={{ fontWeight: 'bolder', fontSize: '22px' }}>
              Loading...
            </div>
          ),
        }}
      />
    </div>
  );
};

export default Dashboard;

const sortByArr = [
  {
    type: 'Mail Ready',
    sort: '-mail_ready,-updatedAt',
  },
  {
    type: 'Mail Not Ready',
    sort: 'mail_ready,-updatedAt',
  },

  {
    type: 'Content Ready',
    sort: '-mail_content,-updatedAt',
  },
  {
    type: 'Content Not Ready',
    sort: 'mail_content,-updatedAt',
  },

  {
    type: 'Mail Open',
    sort: '-mail_open,-updatedAt',
  },
  {
    type: 'Mail Not Open',
    sort: 'mail_open,-updatedAt',
  },

  {
    type: 'Link Click',
    sort: '-mail_link_click,-updatedAt',
  },
  {
    type: 'Link Not Click',
    sort: 'mail_link_click,-updatedAt',
  },

  {
    type: 'Category (Desc)',
    sort: 'category,-updatedAt',
  },
  {
    type: 'Category (Asc)',
    sort: '-category,-updatedAt',
  },

  {
    type: 'Unsubscribed',
    sort: '-mail_unsubscribed,-updatedAt',
  },
  {
    type: 'Not Unsubscribed',
    sort: 'mail_unsubscribed,-updatedAt',
  },

  {
    type: 'Mail Disabled',
    sort: '-mail_disabled,-updatedAt',
  },

  {
    type: 'Not Disabled',
    sort: 'mail_disabled,-updatedAt',
  },

  { type: 'Newest Updated Date', sort: '-updatedAt' },
  { type: 'Oldest Updated Date', sort: 'updatedAt' },

  { type: 'Newest Created Date', sort: '-createdAt' },
  { type: 'Oldest Created Date', sort: 'createdAt' },
];

const COLUMNS = [
  {
    title: 'Mail Send',
    dataIndex: 'mail_send',
    key: 'mail_send',
    render: (text, record) => (
      <span>
        {text ? (
          <Tooltip title="Sent" placement="topLeft">
            YES
          </Tooltip>
        ) : (
          ''
        )}
      </span>
    ),
  },
  {
    title: 'Send Date',
    dataIndex: 'mail_send_date',
    key: 'mail_send_date',
    render: (text, record) => (
      <span style={{ ...(!text && { display: 'none' }) }}>
        {moment(text).fromNow(true)} ago
      </span>
    ),
  },
  // {
  //   title: 'Mail Open',
  //   dataIndex: 'mail_open',
  //   key: 'mail_open',
  //   render: (text, record) => (
  //     <span>
  //       {text ? (
  //         <Tooltip title="Opened" placement="topLeft">
  //           YES
  //         </Tooltip>
  //       ) : (
  //         ''
  //       )}
  //     </span>
  //   ),
  // },
  {
    title: 'Link Click',
    dataIndex: 'mail_link_click',
    key: 'mail_link_click',
    render: (text, record) => (
      <span>
        {text ? (
          <Tooltip title="Clicked" placement="topLeft">
            YES
          </Tooltip>
        ) : (
          ''
        )}
      </span>
    ),
  },
  {
    title: 'Unsubscribed',
    dataIndex: 'mail_unsubscribed',
    key: 'mail_unsubscribed',
    render: (text, record) => (
      <span>
        {text ? (
          <Tooltip title="Unsubscribed" placement="topLeft">
            <Tag color="#f50">YES</Tag>{' '}
          </Tooltip>
        ) : (
          ''
        )}
      </span>
    ),
  },
  {
    title: 'Mail Disabled',
    dataIndex: 'mail_disabled',
    key: 'mail_disabled',
    render: (text, record) => (
      <span>
        {text ? (
          <Tooltip title="Disabled" placement="topLeft">
            <Tag color="#f50">YES</Tag>
          </Tooltip>
        ) : (
          ''
        )}
      </span>
    ),
  },
  {
    title: 'Website',
    dataIndex: 'website',
    key: 'website',
    render: (text, record) => (
      <a href={text} rel="noopener noreferrer" target="_blank">
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </a>
    ),
  },
  {
    title: 'Linkedin',
    dataIndex: 'linkedin_url',
    key: 'linkedin_url',
    render: (text, record) => (
      <>
        {text && (
          <a href={text} rel="noopener noreferrer" target="_blank">
            {`${text}`.substring(0, 25)}
            {`${text}`.length > 25 && '...'}
          </a>
        )}
      </>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Mail IP',
    dataIndex: 'mail_ip',
    key: 'mail_ip',
    render: (text, record) => (
      <>
        {text && (
          <a
            href={`https://whatismyipaddress.com/ip/${text}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${text}`}
          </a>
        )}
      </>
    ),
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    render: (text, record) => (
      <a
        href={`https://instagram.com/${text}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </a>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'fullname',
    key: 'fullname',
    render: (text, record) => (
      <span>
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </span>
    ),
  },

  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },

  {
    title: 'Post',
    dataIndex: 'post',
    key: 'post',
  },
  {
    title: 'Follower',
    dataIndex: 'follower',
    key: 'follower',
  },
  {
    title: 'Following',
    dataIndex: 'following',
    key: 'following',
  },
  {
    title: 'Bio',
    dataIndex: 'bio',
    key: 'bio',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },
];
