import React, { useEffect, useRef } from 'react';

import styled, { createGlobalStyle } from 'styled-components';

const StyledDiv = styled.div`
  #tradingview_my_id {
    width: 100%;
    height: 610px;
  }

  iframe
    #widget-container
    > div.js-rootresizer__contents
    > div.layout__area--top
    > div
    > div
    > div:nth-child(3)
    > div
    > div
    > div
    > div
    > div
    > div:nth-child(10) {
    width: 0px;
  }
`;

const TradingViewWidget = ({ asset = '' }) => {
  const myAsset = asset.replace(/\//, '');

  useEffect(() => {
    if (window.TradingView) {
      createWidget();
    }

    function createWidget() {
      if (
        document.getElementById('tradingview_my_id') &&
        'TradingView' in window
      ) {
        new window.TradingView.widget({
          autosize: true,
          symbol: `FX:${myAsset}`,
          interval: '1', //1 min
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          container_id: 'tradingview_my_id',
          save_image: false,
          hide_top_toolbar: false, //hide top bar such as interval, indicator, candles
          hide_studies: true,
          hide_legend: false, //pair info
        });
      }
    }
  }, []);

  return (
    <StyledDiv className="tradingview-widget-container">
      <div id="tradingview_my_id" />
      {/* <div className="tradingview-widget-copyright">
        <a
          href={`https://in.tradingview.com/symbols/${myAsset}/?exchange=FX`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="blue-text">{asset} chart</span>
        </a>{' '}
        by TradingView
      </div> */}
    </StyledDiv>
  );
};

export default TradingViewWidget;
