import { Dropdown, Input, Menu, Tooltip } from 'antd';
import { useImmer } from 'use-immer';
// @ts-ignore
import debounce from 'lodash.debounce';
import { useActions } from '../../hooks/useActions';
// @ts-ignore
import styled, { createGlobalStyle } from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const { Search } = Input;

// navbar search box dropdown
const GlobalSearchDropdownGlobalStyle = createGlobalStyle`
 /* navbar search box dropdown */
 .global-search-dropdown.ant-dropdown {
 
    max-width: 700px;
    z-index:100 //it should be less than the app modal z-index which is Infinity, 

  }
`;

const StyledSearch = styled(Search)`
  &.my-search .ant-input-suffix .anticon-close-circle.ant-input-clear-icon {
    font-size: 22px;
  }
`;

const SearchBox = (props) => {
  const { bioSearchText, selectedCategory, page, limit, sortBy } = useSelector(
    (state) => state.dashboard
  );

  const { getSearchSuggestion, getVLeads } = useActions();

  const [dropdownMenuVisible, setDropdownMenuVisible] = useImmer(false);

  const [searchSuggestion, setSearchSuggestion] = useImmer([]);

  const onSearchTextChange = (value) => {
    searchTextChangeDebounce(value);
  };

  // search text change debounce fn
  const searchTextChangeDebounce = debounce((searchText) => {
    getSearchSuggestion(searchText, (success, suggestionArr) => {
      success && setSearchSuggestion(suggestionArr);
    });
  }, 1000);

  // when user press enter or click "Search enter"
  //so search for keywords in bio in the selected category if any
  const onSearchClick = (value) => {
    const query = `page=${page}&limit=${limit}&filter=bio:${encodeURIComponent(
      value || ''
    )}`;

    getVLeads(query, undefined, value, (success) => {
      setDropdownMenuVisible(false);
    });
  };

  const onSearchDropdownMenuClick = (e) => {
    setDropdownMenuVisible(true);

    if (!searchSuggestion[e.key]) {
      return;
    }

    let tempSelectedCategory = searchSuggestion[e.key] || '';

    const query = `page=${page}&limit=${limit}&filter=category:${encodeURIComponent(
      tempSelectedCategory || ''
    )}`;

    getVLeads(query, tempSelectedCategory, undefined, (success) => {
      setDropdownMenuVisible(false);
    });
  };

  const onMenuMouseLeave = () => {
    setDropdownMenuVisible(false);
  };
  const onMenuMouseEnter = () => {
    setDropdownMenuVisible(true);
  };

  const menu = (
    <Menu
      onClick={onSearchDropdownMenuClick}
      style={{ maxHeight: '400px', overflowY: 'auto' }}
    >
      <div style={{ marginLeft: '12px', marginTop: '12px' }}>
        <span style={{ fontWeight: 'bolder' }}>Bio Search keywords:&nbsp;</span>
        {bioSearchText}
      </div>
      <div style={{ height: '22px' }}></div>
      <div
        style={{
          fontWeight: 'bolder',
          marginBottom: '8px',
          marginLeft: '12px',
        }}
      >
        Select a Category:
      </div>
      {/* de-select category section */}
      {selectedCategory && (
        <div
          style={{
            height: '36px',
            display: 'flex',
            justifyContent: 'start',
            marginLeft: '12px',
          }}
        >
          <div
            style={{
              marginRight: '8px',
              borderLeft: '1px solid var(--blueColor)',
              borderTop: '1px solid var(--blueColor)',
              borderBottom: '1px solid var(--blueColor)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ padding: '4px' }}>
              {(selectedCategory || '').substring(0, 50)}
            </span>
            <Tooltip title="clear selected category" placement="bottom">
              <div
                style={{
                  cursor: 'pointer',
                  background: 'red',
                  color: 'white',
                  padding: '2px',
                  fontSize: '22px',
                  marginLeft: '8px',
                }}
                onClick={() => {
                  const query = `page=${1}&limit=${limit}`;

                  getVLeads(query, '', '', (success) => {
                    // set empty selected category
                    setDropdownMenuVisible(false);
                  });
                }}
              >
                <CloseOutlined />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      {/* list of categories */}
      {searchSuggestion.map((item, idx) => (
        <Menu.Item key={idx}>
          {/* title */}
          <span
            style={{
              maxWidth: '600px',
              overflow: 'hidden',
            }}
          >
            {item}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <div style={{ marginBottom: '12px' }}>
        Select IG biz category and/or search keywords in bio
      </div>
      {/* for new search box with dropdown*/}
      <GlobalSearchDropdownGlobalStyle />
      <div
        onMouseLeave={onMenuMouseLeave}
        onMouseEnter={onMenuMouseEnter}
        style={{ flex: 1 }}
      >
        <Dropdown
          overlay={menu}
          overlayStyle={{
            display: searchSuggestion.length <= 0 ? 'none' : '',
          }}
          visible={dropdownMenuVisible}
          overlayClassName="global-search-dropdown"
        >
          <StyledSearch
            placeholder="Select a category or enter keywords in bio"
            enterButton="Bio Search"
            size="large"
            loading={false}
            onChange={(e) => onSearchTextChange(e.target.value)}
            onPressEnter={(e) => onSearchClick(e.target.value)}
            onSearch={onSearchClick}
            defaultValue={bioSearchText}
            allowClear
            className="my-search"
            onMouseOver={onMenuMouseEnter}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default SearchBox;
