import produce from 'immer';
import { SET_CURRENT_USER } from '../action-types';
import isEmpty from '../../validation/is-empty';
import { _DEV_ENV_ } from '../../constants';

const INITIAL_STATE = {
  isAuthenticated: false, //make it false
  login_user: {},
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_USER:
        const { login_user } = action.payload || {};
        draft.isAuthenticated = !isEmpty(login_user);
        draft.login_user = login_user;
        break;
      default:
        return state;
    }
  });

export default reducer;
