import produce from 'immer';
import { SET_ECOM_LEADS } from '../action-types';

const INITIAL_STATE = {
  page: 1,
  total: null,
  limit: 20,
  ecomLeads: [],
  selectedVertical: '',

  searchSuggestion: [],
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ECOM_LEADS:
        const { page, ecomLeads, total, limit, selectedVertical } =
          action.payload;

        draft.page = page;
        draft.ecomLeads = ecomLeads;
        draft.total = total;
        draft.limit = limit;

        draft.selectedVertical = selectedVertical || '';

        break;
      default:
        return state;
    }
  });

export default reducer;
