import moment from 'moment';

// iq option broker
const BROKER_IQ_OPTION = 'IQ Option';
// map required keys to broker data
const BROKER_IQ_OPTION_KEYS = {
  'Position ID': 'Position ID',
  Direction: 'Direction',
  Asset: 'Asset',
  'Opening Date Time': 'Opening Date Time',
  'Opening price': 'Opening price',
  'Closing Date Time': 'Closing Date Time',
  'Closing price': 'Closing price',
  Investments: 'Investments',
  'Net PnL': 'Net PnL',
};
// qtx broker
const BROKER_QUOTEX = 'Quotex';
// map required keys to broker data
const BROKER_QUOTEX_KEYS = {
  'Position ID': 'ID',
  Direction: 'Type',
  Asset: 'Info',
  'Opening Date Time': 'Open time',
  'Opening price': 'Open Price',
  'Closing Date Time': 'Close Time',
  'Closing price': 'Close Price',
  Investments: 'Amount',
  'Net PnL': 'Income',
};
const formatCsvJSONData = (csvJsonDataArr, broker, currency) => {
  // console.log('logs: ', csvJsonDataArr);

  let finalJsonData = [];

  if (!Array.isArray(csvJsonDataArr)) {
    return finalJsonData;
  }

  if (broker === BROKER_IQ_OPTION) {
    const brokerKeysArr = Object.keys(BROKER_IQ_OPTION_KEYS);

    csvJsonDataArr.map((itemObj, idx) => {
      let tempData = {
        broker,
        currency,
        broker_data: itemObj,
      };
      brokerKeysArr.map((key, idx2) => {
        tempData = { ...tempData, [key]: itemObj[BROKER_IQ_OPTION_KEYS[key]] };
      });

      finalJsonData.push(tempData);
    });
  } else if (broker === BROKER_QUOTEX) {
    const brokerKeysArr = Object.keys(BROKER_QUOTEX_KEYS);

    csvJsonDataArr.map((itemObj, idx) => {
      let tempData = {
        broker,
        currency,
        broker_data: itemObj,
      };
      brokerKeysArr.map((key, idx2) => {
        tempData = { ...tempData, [key]: itemObj[BROKER_QUOTEX_KEYS[key]] };
      });

      finalJsonData.push(tempData);
    });

    // transform data to match common format
    // transform key "Type": "Down" to "put" and "Up" to "call"
    // transform key "Income": "Income" -  'Amount'
    // transform key "Open time" and "Close Time" : "'2023-05-22 10:14:19" which is already in IST to "2023-04-20T09:48:00+05:30"

    finalJsonData = finalJsonData.map((itemObj, idx) => {
      let netPnL = Number(itemObj['Net PnL']);
      let investments = Number(itemObj['Investments']);
      netPnL = `${parseFloat(netPnL - investments).toFixed(2)}`;

      return {
        ...itemObj,
        Direction: itemObj.Direction === 'Up' ? 'call' : 'put',
        'Net PnL': netPnL,

        'Opening Date Time': new moment(
          itemObj['Opening Date Time'],
          'YYYY-MM-DD HH:mm:ss'
        )
          .utcOffset('+05:30')
          .format('YYYY-MM-DDTHH:mm:ssZZ'),

        'Closing Date Time': new moment(
          itemObj['Closing Date Time'],
          'YYYY-MM-DD HH:mm:ss'
        )
          .utcOffset('+05:30')
          .format('YYYY-MM-DDTHH:mm:ssZZ'),
      };
    });
    // end of transform
  }

  // console.log('logs: ', finalJsonData);

  return finalJsonData;
};

export default formatCsvJSONData;
