import { Button, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';

const AppModal = (props) => {
  const { loading, message } = useSelector((state) => state.config);

  const { updateConfig } = useActions();

  return (
    <Modal
      title={
        message ? (
          <span
            style={{
              color: 'var(--blueColor)',
              fontWeight: 'bolder',
              fontSize: '22px',
            }}
          >
            {'INFO'}
          </span>
        ) : (
          'Loading...'
        )
      }
      visible={loading || !!message}
      destroyOnClose
      footer={false}
      centered
      keyboard={false}
      maskClosable={false}
      closable={false}
      zIndex={10000000000}
    >
      {!message && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}
      {message && (
        <div>
          <div style={{ marginTop: '12px', fontSize: '18px' }}>{message}</div>

          <div
            style={{
              width: '100%',
              textAlign: 'right',
              marginTop: '12px',
            }}
          >
            <Button
              type={'primary'}
              size="large"
              onClick={() => {
                updateConfig({ message: '', loading: false });
              }}
              style={{ fontWeight: 'bold', fontSize: '18px' }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AppModal;
