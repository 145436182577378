import Analyzer from './log-analyzer';

// const format = `:remote-addr - :remote-user [:date[iso]] ":method :url HTTP/:http-version" :status :response-time ms - :res[content-length] ":referrer" ":user-agent"`;
const format = `:remote-addr - :remote-user [:date] ":method :url HTTP/:http-version" :status :response-time ms - :res ":referrer" ":user-agent"`;

const analyzeServerLog = (text) => {
  if (!text) {
    return {};
  }
  const analyzer = new Analyzer(format, { ignoreMismatches: true });
  const resultNow = analyzer.analyze(text);
  // console.log(resultNow.logs[0]);
  const mismatchSet = new Set(resultNow.mismatch_logs || []);

  return { logs: resultNow.logs || [], mismatchLogs: Array.from(mismatchSet) };
};

export default analyzeServerLog;
