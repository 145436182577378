import { useImmer } from 'use-immer';
import { useEffect, useRef, useState } from 'react';

import { Button, Popover, Tag, Tooltip } from 'antd';
import moment from 'moment';
import _ from 'lodash';

const MyTimeline = ({
  dataArr,
  dataColor,
  emptyColor,
  tradeColor,
  title,
  tradesArr,

  showBinarySession,
  defaultSessArr,
  newsSessArr,
}) => {
  const [nowTimePct, setNowTimePct] = useState(0);
  const [nowHr, setNowHr] = useState('');
  const [nowMin, setNowMin] = useState('');
  // remaining trading time left for binary trading
  const [bNowTimePct, setBNowTimePct] = useState('');

  // binary
  const [bFormattedSessionTvArr, setBFormattedSessionTvArr] = useState([]);
  const [bTotalTradeTime, setBTotalTradeTime] = useState(0);

  // forex
  // formatted default session arr
  const [fDefaultSessionTvArr, setFDefaultSessionTvArr] = useState([]);
  // formatted news session arr
  const [fNewsSessionTvArr, setFNewsSessionTvArr] = useState([]);
  const [fTotalTradeTime, setFTotalTradeTime] = useState(0);
  //news time, high impact: 3 star, medium: 2 star, low: 1 star
  const [newsImpact, setNewsImpact] = useState(3); // empty str i.e. "" show all news

  useEffect(() => {
    let timer1 = setInterval(() => {
      const nowHr = moment().hour(); //19 i.e. 7 PM
      setNowHr(nowHr < 10 ? `0${nowHr}` : nowHr);
      const nowMin = moment().minutes();
      setNowMin(nowMin < 10 ? `0${nowMin}` : nowMin);
      const totalNowMin = nowHr * 60 + nowMin;
      const nowTimePct = getTimePct(totalNowMin);

      setNowTimePct(nowTimePct);

      // remaining time
      let remainingTimeLeft = 0;
      Array.isArray(dataArr) &&
        dataArr.map((itemObj, idx) => {
          if (totalNowMin >= itemObj.start && totalNowMin <= itemObj.end) {
            remainingTimeLeft += Math.abs(itemObj.end - totalNowMin);
          } else if (
            totalNowMin < itemObj.start &&
            totalNowMin < itemObj.start
          ) {
            remainingTimeLeft += Math.abs(itemObj.end - itemObj.start);
          }
        });
      setBNowTimePct(
        `${parseInt(remainingTimeLeft / 60)} hr ${remainingTimeLeft % 60} min`
      );
    }, 1 * 1000); //1 sec

    return () => {
      clearInterval(timer1);
    };
  }, []);

  // convert time in min to pct in timeline
  const getTimePct = (totalNowMin) => {
    const totalMin = 24 * 60;
    return Math.round((totalNowMin * 100) / totalMin);
  };

  let tempTradesArr = [];

  Array.isArray(tradesArr) &&
    tradesArr.map((itemObj, idx) => {
      const tempTimeHour = moment(itemObj['Closing Date Time']).format('HH');
      const tempTimeMin = moment(itemObj['Closing Date Time']).format('mm');

      Number(itemObj['Net PnL']) &&
        tempTradesArr.push({
          is_win: Number(itemObj['Net PnL']) > 0,
          time_min: parseInt(tempTimeHour) * 60 + parseInt(tempTimeMin),
        });
    });

  // for binary
  useEffect(() => {
    // if (!showBinarySession) {
    //   return;
    // }
    // console.log({ tradesArr, tempTradesArr });

    // convert to format {value: end-start ,pair: "USD"}, also include empty space

    let tempDataArr = [];

    Array.isArray(dataArr) &&
      dataArr.map((itemObj, idx) => {
        let noOfTrades = 0;
        let noOfWin = 0;
        // trade stats
        tempTradesArr.map((item) => {
          if (item.time_min >= itemObj.start && item.time_min <= itemObj.end) {
            ++noOfTrades;

            noOfWin += item.is_win ? 1 : 0;
          }
        });

        const win_ratio =
          (noOfTrades && `${parseInt((noOfWin * 100) / noOfTrades)} %`) || '';

        // start gap or empty space
        if (idx === 0) {
          tempDataArr.push({
            value_pct: ((itemObj.start - 0) * 100) / 1440,
            pair: '',
            time_tv_str: '',
            color: emptyColor,
            trade_color: tradeColor,
            win_ratio,
            noOfTrades,
          });
        } else {
          // other gap or empty space
          tempDataArr.push({
            value_pct: ((itemObj.start - dataArr[idx - 1].end) * 100) / 1440,
            pair: itemObj.pair || '',
            time_tv_str: '',
            color: emptyColor,
            trade_color: tradeColor,
            win_ratio,
            noOfTrades,
          });
        }

        // data value
        tempDataArr.push({
          value_pct: ((itemObj.end - itemObj.start) * 100) / 1440,
          pair: itemObj.pair || '',
          time_tv_str: itemObj.time_tv_str || '',
          color: dataColor,
          trade_color: tradeColor,
          time_diff_min: Math.abs(itemObj.end - itemObj.start),
          win_ratio,
          noOfTrades,
        });

        // end gap or empty space
        if (idx === dataArr.length - 1) {
          tempDataArr.push({
            value_pct: ((1440 - itemObj.end) * 100) / 1440,
            pair: '',
            time_tv_str: '',
            color: emptyColor,
            trade_color: tradeColor,
            win_ratio,
            noOfTrades,
          });
        }
      });

    setBFormattedSessionTvArr(tempDataArr);

    // console.log({ tempDataArr });

    let tempTotalTradeTime = 0;

    tempDataArr.map((itemObj) => {
      if (itemObj.time_diff_min) {
        tempTotalTradeTime += parseInt(itemObj.time_diff_min);
      }
    });

    tempTotalTradeTime = `${parseInt(tempTotalTradeTime / 60)} hr ${parseInt(
      tempTotalTradeTime % 60
    )} min`;
    setBTotalTradeTime(tempTotalTradeTime);
  }, []);

  // for forex
  useEffect(() => {
    // if (!showBinarySession) {
    //   return;
    // }
    // console.log({ tradesArr, tempTradesArr });

    // convert to format {value: end-start ,pair: "USD"}, also include empty space

    let tempDataArr = [];

    console.log({ defaultSessArr });
    Array.isArray(defaultSessArr) &&
      defaultSessArr.map((itemObj, idx) => {
        let start = itemObj.start;
        let end = itemObj.end;
        let noOfTrades = 0;
        let noOfWin = 0;
        // trade stats
        tempTradesArr.map((item) => {
          if (item.time_min >= itemObj.start && item.time_min <= itemObj.end) {
            ++noOfTrades;

            noOfWin += item.is_win ? 1 : 0;
          }
        });

        const win_ratio =
          (noOfTrades && `${parseInt((noOfWin * 100) / noOfTrades)} %`) || '';

        // start gap or empty space
        if (idx === 0) {
          tempDataArr.push({
            value_pct: ((itemObj.start - 0) * 100) / 1440,
            pair: '',
            time_tv_str: '',
            color: emptyColor,
            trade_color: tradeColor,
            win_ratio,
            noOfTrades,
            start,
            end,
          });
        } else {
          // other gap or empty space
          tempDataArr.push({
            value_pct: ((itemObj.start - dataArr[idx - 1].end) * 100) / 1440,
            pair: itemObj.pair || '',
            time_tv_str: '',
            color: emptyColor,
            trade_color: tradeColor,
            win_ratio,
            noOfTrades,
            start,
            end,
          });
        }

        // data value
        tempDataArr.push({
          value_pct: ((itemObj.end - itemObj.start) * 100) / 1440,
          pair: itemObj.pair || '',
          time_tv_str: itemObj.time_tv_str || '',
          color: dataColor,
          trade_color: tradeColor,
          time_diff_min: Math.abs(itemObj.end - itemObj.start),
          win_ratio,
          noOfTrades,
          start,
          end,
        });

        // end gap or empty space
        if (idx === dataArr.length - 1) {
          tempDataArr.push({
            value_pct: ((1440 - itemObj.end) * 100) / 1440,
            pair: '',
            time_tv_str: '',
            color: emptyColor,
            trade_color: tradeColor,
            win_ratio,
            noOfTrades,
            start,
            end,
          });
        }
      });

    setFDefaultSessionTvArr(tempDataArr);

    console.log({ tempDataArr });

    let tempTotalTradeTime = 0;

    tempDataArr.map((itemObj) => {
      if (itemObj.time_diff_min) {
        tempTotalTradeTime += parseInt(itemObj.time_diff_min);
      }
    });

    tempTotalTradeTime = `${parseInt(tempTotalTradeTime / 60)} hr ${parseInt(
      tempTotalTradeTime % 60
    )} min`;
    setFTotalTradeTime(tempTotalTradeTime);
  }, []);

  // console.log({ tempSum });

  // convert 0401-0450 to 04:01 - 04:50
  const getTimeFromTvStr = (timeStr) => {
    if (!timeStr) {
      return '';
    }
    let tempStr = timeStr.split('-');

    tempStr =
      tempStr[0].substring(0, 2) +
      ':' +
      tempStr[0].substring(2) +
      '-' +
      tempStr[1].substring(0, 2) +
      ':' +
      tempStr[1].substring(2);

    return tempStr;
  };

  return (
    // {/* plot default session */}
    <>
      <div
        style={{
          fontSize: '18px',
          marginBottom: showBinarySession ? '90px' : '30px',
        }}
      >
        {title}&nbsp;
        <Tag color={'green'}>
          {showBinarySession ? bTotalTradeTime : fTotalTradeTime}
        </Tag>
        {showBinarySession && bNowTimePct && (
          <span style={{ color: 'red' }}>
            Remaining:&nbsp;
            <Tag color={'red'}>{bNowTimePct}</Tag>
          </span>
        )}
      </div>

      {!showBinarySession && (
        <div
          style={{
            display: 'flex',
            marginBottom: '100px',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <div>News Impact</div>
          <div style={{ marginLeft: '20px' }}>
            <Button
              onClick={() => {
                setNewsImpact('');
              }}
              style={{
                background: 'black',
                marginRight: '4px',
                color: 'white',
              }}
            >
              All
            </Button>
            <Button
              onClick={() => {
                setNewsImpact(3);
              }}
              style={{ background: 'red', marginRight: '4px', color: 'white' }}
            >
              High
            </Button>
            <Button
              onClick={() => {
                setNewsImpact(2);
              }}
              style={{
                background: 'yellow',
                marginRight: '4px',
                color: 'white',
              }}
            >
              Medium
            </Button>
            <Button
              onClick={() => {
                setNewsImpact(1);
              }}
              style={{ background: 'blue', marginRight: '4px', color: 'white' }}
            >
              Low
            </Button>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          // flexDirection: 'column',
          height: '100%',
          width: '100%',
          // fir showing vertical red line
          position: 'relative',
        }}
        // onMouseMove={(e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   // const rect = e.target.getBoundingClientRect();
        //   // const x = e.clientX - rect.left; //x position within the element.
        //   // console.log('here: ', e.clientX - e.currentTarget.offsetLeft);
        //   let tempLeft = e.clientX - e.currentTarget.offsetLeft;
        //   tempLeft = parseInt((tempLeft * 100) / widthTimeline);
        //   tempLeft = (tempLeft * 1440) / 100;
        //   tempLeft = parseInt(tempLeft / 60) + ':' + (tempLeft % 60);
        //   console.log('time: ', tempLeft);
        // }}
        // ref={myTimelineRef}
        className="testBlu1"
      >
        {/* show running time vertical red line */}
        <Tooltip
          title={<div>{`${nowHr}:${nowMin}`}</div>}
          trigger={'click'}
          placement={'top'}
          autoAdjustOverflow
          color={'red'}
          arrowPointAtCenter={true}
          open={!!nowHr || !!nowMin}
        >
          <div
            style={{
              background: 'red',
              height: '100%',
              width: '2px',
              marginLeft: `${nowTimePct}%`,
              position: 'absolute',
              cursor: 'pointer',
              zIndex: 10,
              borderColor: 'white',
            }}
          ></div>
        </Tooltip>

        {/* for binary session */}
        {showBinarySession && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              height: '100%',
              width: '100%',
              position: 'relative',
              zIndex: 1,
              background: 'transparent',
            }}
          >
            {Array.isArray(bFormattedSessionTvArr) &&
              bFormattedSessionTvArr.map((itemObj, idx) => {
                return (
                  <Tooltip
                    title={
                      <div>
                        <div>
                          {getTimeFromTvStr(itemObj.time_tv_str)}&nbsp;
                          {itemObj.pair || ''}
                        </div>

                        <div style={{ color: 'green' }}>
                          {itemObj.time_diff_min} min
                        </div>
                      </div>
                    }
                    key={'b-' + idx}
                    trigger={'click'}
                    open={!!itemObj.time_tv_str}
                    placement={idx % 3 === 0 ? 'bottom' : 'top'}
                    autoAdjustOverflow
                    color={'yellow'}
                    arrowPointAtCenter={true}
                  >
                    <Popover
                      content={
                        <div
                          style={{
                            color: 'green',
                          }}
                        >
                          {itemObj.time_diff_min && (
                            <div style={{ marginBottom: '12px' }}>
                              {' '}
                              {itemObj.time_diff_min} min
                            </div>
                          )}
                          {itemObj.noOfTrades > 0 && (
                            <div>
                              <div>Win: {itemObj.win_ratio}</div>
                              <div>Trades: {itemObj.noOfTrades}</div>
                            </div>
                          )}
                        </div>
                      }
                      title={getTimeFromTvStr(itemObj.time_tv_str)}
                      placement={idx % 3 === 0 ? 'top' : 'bottom'}
                      trigger="click"
                      overlayStyle={{ zIndex: 9999 }}
                      // open={true}
                      // key={'b-2' + idx}
                    >
                      <div
                        style={{
                          width: `${
                            itemObj.value_pct > 0 ? itemObj.value_pct : 0
                          }%`,
                          height: '100%',
                          background: itemObj.color,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {itemObj.noOfTrades > 0 && (
                          <div
                            style={{
                              background: itemObj.trade_color,
                              height: '70%',
                              width: '100%',
                            }}
                          ></div>
                        )}
                      </div>
                    </Popover>
                  </Tooltip>
                );
              })}
          </div>
        )}

        {/* for fx session */}
        {!showBinarySession && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              height: '100%',
              width: '100%',
              position: 'relative',
              zIndex: 1,
              background: emptyColor,
            }}
          >
            {/* default session arr */}
            {console.log({ defaultSessArr, fDefaultSessionTvArr, newsSessArr })}
            {Array.isArray(fDefaultSessionTvArr) &&
              fDefaultSessionTvArr.map((itemObj, idx) => {
                // if (!itemObj.time_tv_str) {
                //   return null;
                // }

                return (
                  <Tooltip
                    title={
                      <div>
                        <div>
                          {getTimeFromTvStr(itemObj.time_tv_str)}&nbsp;
                          {itemObj.pair || ''}
                        </div>

                        <div style={{ color: 'green' }}>
                          {itemObj.time_diff_min} min
                        </div>
                      </div>
                    }
                    key={'fx-d-' + idx}
                    trigger={'click'}
                    open={!!itemObj.time_tv_str}
                    placement={idx % 3 === 0 ? 'bottom' : 'top'}
                    autoAdjustOverflow
                    color={'yellow'}
                    arrowPointAtCenter={true}
                  >
                    {/* default green session */}
                    <Popover
                      content={
                        <div
                          style={{
                            color: 'green',
                          }}
                        >
                          {itemObj.time_diff_min && (
                            <div style={{ marginBottom: '12px' }}>
                              {itemObj.time_diff_min} min
                            </div>
                          )}
                          {itemObj.noOfTrades > 0 && (
                            <div>
                              <div>Win: {itemObj.win_ratio}</div>
                              <div>Trades: {itemObj.noOfTrades}</div>
                            </div>
                          )}
                        </div>
                      }
                      title={getTimeFromTvStr(itemObj.time_tv_str)}
                      placement={idx % 3 === 0 ? 'top' : 'bottom'}
                      trigger="click"
                      overlayStyle={{ zIndex: 9999 }}
                      // open={true}
                      // key={'fx-d2-' + idx}
                    >
                      <div
                        style={{
                          // width: `${
                          //   itemObj.value_pct > 0 ? itemObj.value_pct : 0
                          // }%`,
                          // height: '100%',
                          // background: itemObj.color,
                          // display: 'flex',
                          // justifyContent: 'center',
                          // alignItems: 'center',

                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: itemObj.color,
                          height: '100%',
                          width: `${
                            (Math.abs(itemObj.start - itemObj.end) * 100) /
                            (24 * 60)
                          }%`,
                          marginLeft: `${(itemObj.start * 100) / (24 * 60)}%`,
                          position: 'absolute',
                          cursor: 'pointer',
                          zIndex: 2,
                        }}
                        // key={'fx-d3-' + idx}
                      >
                        {itemObj.noOfTrades > 0 && (
                          <div
                            style={{
                              background: itemObj.trade_color,
                              height: '70%',
                              width: '100%',
                            }}
                          ></div>
                        )}
                      </div>
                    </Popover>
                  </Tooltip>
                );
              })}

            {/* news session arr */}
            {/* {console.log({ newsSessArr })} */}
            {Array.isArray(newsSessArr) &&
              newsSessArr.map((itemObj, idx) => {
                if (
                  newsImpact &&
                  parseInt(itemObj.star) &&
                  parseInt(itemObj.star) !== newsImpact
                ) {
                  return null;
                }
                return (
                  // {/* news session */}
                  <Popover
                    content={
                      <div
                        style={{
                          color:
                            parseInt(itemObj.star) === 3
                              ? 'red'
                              : parseInt(itemObj.star) === 2
                              ? 'yellow'
                              : parseInt(itemObj.star) === 1
                              ? 'blue'
                              : '',
                        }}
                      >
                        <div style={{ marginBottom: '12px' }}>
                          {Math.abs(itemObj.start - itemObj.end)} min
                        </div>

                        <div style={{ marginBottom: '12px' }}>
                          {getTimeFromTvStr(itemObj.time_tv_str)}
                        </div>

                        <div style={{ marginBottom: '12px' }}>
                          {itemObj.pair}
                        </div>
                      </div>
                    }
                    title={itemObj.time}
                    placement={idx % 3 === 0 ? 'top' : 'bottom'}
                    trigger="click"
                    overlayStyle={{ zIndex: 9999 }}
                    // open={true}
                    key={'fx-n' + idx}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background:
                          parseInt(itemObj.star) === 3
                            ? 'red'
                            : parseInt(itemObj.star) === 2
                            ? 'yellow'
                            : parseInt(itemObj.star) === 1
                            ? 'blue'
                            : '',
                        opacity: '0.6',
                        height: '100%',
                        width: `${
                          (Math.abs(itemObj.start - itemObj.end) * 100) /
                          (24 * 60)
                        }%`,
                        marginLeft: `${(itemObj.start * 100) / (24 * 60)}%`,
                        position: 'absolute',
                        cursor: 'pointer',
                        zIndex: 2,
                      }}
                    ></div>
                  </Popover>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default MyTimeline;
