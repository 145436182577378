import React from 'react';
import { Modal, Button, Form, Input, Menu, Dropdown, Tooltip } from 'antd';
import { _DEV_ENV_ } from '../../constants';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { useActions } from '../../hooks/useActions';
import isEmpty from '../../validation/is-empty';
import { tryParseJSON } from '../../utils/tryParseJSON';
import buildFirstLine from '../../utils/buildFirstLine';

const MailContentModal = (props) => {
  const modalVisible = props.modalVisible;
  const userObj = props.userObj || {};
  const { getInScrapeData, updateVLead } = useActions();

  // default mail contents
  // const CTA_VALUE = `https://useleadshouzz.com/?vid=${userObj.id}&utm_source=mail&_lh_v=2&_lh_open_v=1`;
  // const TRACKING_IMG_LINK = `https://leadshouzz-marketing.onrender.com/api/v1/v-leads/campaign/open/${userObj.id}`;
  // const SIGNATURE_VALUE = `Best, \nSudhir From LeadsHouzz.\n\nMN Rd, Bbsr, OD 752054`;

  // const UNSUBSCRIBE_LINK = `https://useleadshouzz.com/?unsubscribe_vid=${userObj.id}&_lh_v=2`;
  const UNSUBSCRIBE_LINK = `https://useleadshouzz.com/?unsubscribe_vid=${userObj.id}`;

  const modalContent = userObj.mail_content || {};
  // disable button after click
  const [disabled, setDisabled] = useImmer(false);

  //scrape firstlines
  const [scrapeFirstlines, setScrapeFirstlines] = useImmer([...FIRSTLINE_INFO]);
  const [liName, setLiName] = useImmer('');

  //and forms
  const [form] = Form.useForm();

  useEffect(() => {
    //
  }, []);

  const handleUpdateCategory = (e) => {
    // console.log(e);
    let updatedRowObj = {
      subject: e.subject.trim(),
      firstline: e.firstline.trim(),
      angle: (e.angle || '').trim(),
      unsubscribeLink: e.unsubscribeLink.trim(),

      // cta: (e.cta || '').trim(),
      // trackImgLink: (e.trackImgLink || '').trim(),
      // signature: e.signature.trim(),
    };
    // console.log(updatedRowObj);

    setDisabled(true);
    props.updateMailContent(
      {
        id: userObj.id,
        mail_content: updatedRowObj,
        // mail_ready: true,
      },
      (success) => {
        success && setDisabled(false);
      }
    );
  };

  const handleCancel = () => {
    props.handleModalVisibility(false);
  };

  const handleCopy = () => {
    const modalForm = form.getFieldValue();

    navigator.clipboard.writeText(
      JSON.stringify({
        subject: modalForm.subject,
        firstline: modalForm.firstline,
        angle: modalForm.angle,
      })
    );
  };

  const handlePaste = async () => {
    const formDataStr = await navigator.clipboard.readText();

    const formData = tryParseJSON(formDataStr);

    formData && form.setFieldsValue(formData);
  };

  const subjectFirstlineAngleMenu = (type, INFO_ARR) => {
    return (
      <Menu
        onClick={({ key }) => {
          let infoVal = INFO_ARR[key];
          if (type === 'firstline') {
            infoVal = `Hey${liName ? ` ${liName},` : ','} ${INFO_ARR[key]}`;
          }
          form.setFieldsValue({
            [type]: infoVal,
          });
        }}
      >
        {INFO_ARR.map((item, idx) => (
          <Menu.Item key={idx}>{item}</Menu.Item>
        ))}
      </Menu>
    );
  };

  // linkedin scrape data link: https://codebeautify.org/jsonviewer/y22839654
  const getInScrapeNow = (url = '') => {
    updateVLead(
      {
        linkedin_url: url.trim(),
        id: userObj.id,
      },
      async (success) => {
        if (success) {
          props.getLeads();
        }
        if (!url || !success) {
          return;
        }

        await new Promise((resolve) => setTimeout(resolve, 2 * 1000));

        getInScrapeData(url, (success2, data) => {
          console.log(success2, data);
          if (success2) {
            const { name = '', firstlines } = buildFirstLine(data);
            setLiName(name);

            if (Array.isArray(firstlines)) {
              let tempFirstLines = [];

              firstlines.map((item) => !!item && tempFirstLines.push(item));

              setScrapeFirstlines(tempFirstLines);
            }
          }
        });
      }
    );
  };

  return (
    <>
      <Modal
        title={
          <div>
            <div>{userObj.id}</div>
            <div style={{ display: 'flex' }}>
              <div>{userObj.email}</div>
              <span style={{ marginLeft: '22px', marginRight: '22px' }}>|</span>

              <div>{userObj.category}</div>
            </div>

            <div style={{ display: 'flex' }}>
              <a
                href={`https://instagram.com/${userObj.username}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                IG: {`${userObj.username}`.substring(0, 25)}
                {`${userObj.username}`.length > 25 && '...'}
              </a>
              <span style={{ marginLeft: '22px', marginRight: '22px' }}>|</span>

              <a
                href={
                  'https://www.google.com/search?q=' +
                  encodeURIComponent(
                    `${userObj.fullname} ${userObj.category} "Owner" OR "Founder" OR "CEO" OR "CTO" OR "CMO" site:linkedin.com`.replace(
                      /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu,
                      ''
                    )
                  )
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                G Search: {userObj.fullname}
              </a>
            </div>
            <div>
              <a
                href={userObj.website}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`${userObj.website}`.substring(0, 25)}
                {`${userObj.website}`.length > 25 && '...'}
              </a>
            </div>
          </div>
        }
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
        centered
      >
        {/* copy and paste buttons */}

        <div
          style={{
            marginBottom: '22px',
          }}
        >
          {navigator.clipboard && <Button onClick={handleCopy}>Copy</Button>}
          {navigator.clipboard && (
            <Button
              style={{ marginLeft: '8px' }}
              onClick={handlePaste}
              disabled={
                !!userObj.mail_unsubscribed ||
                !!userObj.mail_send ||
                !!userObj.mail_disabled
              }
            >
              Paste
            </Button>
          )}
          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => {
              props.updateMailContent({ id: userObj.id, mail_content: null });
            }}
            disabled={
              !!userObj.mail_unsubscribed ||
              !!userObj.mail_send ||
              !!userObj.mail_disabled
            }
          >
            Clear
          </Button>

          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => {
              props.updateMailContent({
                id: userObj.id,
                mail_disabled: !userObj.mail_disabled,
              });
            }}
            disabled={!!userObj.mail_unsubscribed || !!userObj.mail_send}
          >
            {!!userObj.mail_disabled ? 'Enable' : 'Disable'}
          </Button>
        </div>
        {_DEV_ENV_ && (
          <div>
            <Tooltip title="Press Enter to save Linkedin url only">
              <Input.Search
                placeholder="Linkedin Url"
                allowClear
                enterButton="Scrape"
                size="large"
                onSearch={getInScrapeNow}
                defaultValue={userObj.linkedin_url || ''}
                onPressEnter={(e) => {
                  const url = e.target.value || '';
                  updateVLead({
                    linkedin_url: url.trim(),
                    id: userObj.id,
                  });
                }}
              />
            </Tooltip>
          </div>
        )}

        {/* Update form */}
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="update-mail-content-form"
          initialValues={{
            // name: modalContent.name,
            subject: modalContent.subject,
            firstline: modalContent.firstline,
            angle: modalContent.angle,
            // cta: modalContent.cta || CTA_VALUE,
            // trackImgLink: modalContent.trackImgLink || TRACKING_IMG_LINK,
            // signature: modalContent.signature || SIGNATURE_VALUE,
            unsubscribeLink: modalContent.unsubscribeLink || UNSUBSCRIBE_LINK,
          }}
          onFinish={handleUpdateCategory}
        >
          {/* Form Subject */}
          <Dropdown
            overlay={subjectFirstlineAngleMenu('subject', SUBJECT_INFO)}
            trigger="click"
          >
            <Form.Item
              label="Subject"
              name="subject"
              rules={[
                {
                  required: true,
                  message: 'Input your subject!',
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Dropdown>

          {/* Form First Line */}
          <Dropdown
            overlay={subjectFirstlineAngleMenu('firstline', scrapeFirstlines)}
            trigger="click"
          >
            <Form.Item
              label="First Line"
              name="firstline"
              rules={[
                {
                  required: true,
                  message: 'Input your firstline!',
                },
              ]}
            >
              <Input.TextArea showCount style={{ height: '100px' }} />
            </Form.Item>
          </Dropdown>
          {/* Form Body */}
          <Dropdown
            overlay={subjectFirstlineAngleMenu('angle', Angle_INFO)}
            trigger="click"
          >
            <Form.Item
              label="Angle"
              name="angle"
              rules={[
                {
                  required: true,
                  message: 'Input your angle!',
                },
              ]}
            >
              <Input.TextArea showCount style={{ height: '200px' }} />
            </Form.Item>
          </Dropdown>
          {/* Form CTA */}
          {/* <Form.Item
            label="CTA"
            name="cta"
            rules={[
              {
                required: !true,
                message: 'Input your cta!',
              },
            ]}
          >
            <Input.TextArea readOnly showCount style={{ height: '100px' }} />
          </Form.Item> */}

          {/* Form Tracking Img */}
          {/* <Form.Item
            label="Tracking Image Link"
            name="trackImgLink"
            rules={[
              {
                required: !true,
                message: 'Input your trackImgLink!',
              },
            ]}
          >
            <Input.TextArea readOnly showCount style={{ height: '100px' }} />
          </Form.Item> */}

          {/* Form Signature */}
          {/* <Form.Item
            label="Signature"
            name="signature"
            rules={[
              {
                required: !true,
                message: 'Input your signature!',
              },
            ]}
          >
            <Input.TextArea showCount readOnly style={{ height: '100px' }} />
          </Form.Item> */}

          {/* Form Unsubscribe Link */}
          <Form.Item
            label="Unsubscribe Link"
            name="unsubscribeLink"
            rules={[
              {
                required: !true,
                message: 'Input your unsubscribeLink!',
              },
            ]}
          >
            <Input.TextArea readOnly showCount style={{ height: '100px' }} />
          </Form.Item>

          {/* Update btn */}
          <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                disabled ||
                !!userObj.mail_unsubscribed ||
                !!userObj.mail_send ||
                !!userObj.mail_disabled
              }
            >
              {isEmpty(userObj.mail_content) ? 'Save' : 'Update'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MailContentModal;

const SUBJECT_INFO = [
  'Quick question',
  // 'Quick Question',
  'A quick question about {{Company}}',
  'A quick question for {{Name}}',
];

const FIRSTLINE_INFO = [
  // 'I stumbled upon your IG profile and saw that you are a {{digital innovator}}. I have an idea for you.',
  // 'I stumbled upon your IG profile and saw that you {{expand the online experience of big players}}. I have an idea for you.',
  // "Hey, I stumbled upon your IG profile and checked out {{Riddik's}} testimonial on your website. I have an idea for you.",
  // 'I stumbled upon your IG profile and saw that you create {{stories for big players}}. I have an idea for you.',
];

const Angle_INFO = [
  'Do you get clients with outbound marketing? Let me know I have a legendary offer which gets 8X clients.',
  // 'Do you want to bump up your Client base? \nI made a video for you: {{CTA Check out this 1 min video}}',
];
