export const _DEV_ENV_ = process.env.NODE_ENV === 'development';

export const GOOGLE_OAUTH2_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;

export const TRADE_DRAWER_POSITION = 'bottom';

// news star waiting time keys
export const STAR_3_WAIT_BEFORE_AFTER_TIME_MIN_KEY =
  'STAR_3_WAIT_BEFORE_AFTER_TIME_MIN';
export const STAR_2_WAIT_BEFORE_AFTER_TIME_MIN_KEY =
  'STAR_2_WAIT_BEFORE_AFTER_TIME_MIN';
export const STAR_1_WAIT_BEFORE_AFTER_TIME_MIN_KEY =
  'STAR_1_WAIT_BEFORE_AFTER_TIME_MIN';
