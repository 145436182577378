import { SET_CONFIG } from '../action-types';

export const updateConfig = (config) => async (dispatch, getState) => {
  dispatch({
    type: SET_CONFIG,
    payload: {
      ...config,
    },
  });
};
