import axios from 'axios';
import { store } from '../state';
import { deleteAccessToken, updateConfig } from '../state/action-creators';
// import { SERVER_ENDPOINT } from '../constants';

const setAuthToken = (token = '') => {
  //set baseURL

  if (token) {
    // Apply to every request
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }

  // interceptors response
  axios.interceptors.response.use(
    (response) => {
      const resObj = response.data || {};
      // if it has d_token, then delete the token
      if (resObj.hasOwnProperty('d_token')) {
        store.dispatch(deleteAccessToken());
        store.dispatch(
          updateConfig({ message: resObj.message, isError: true })
        );

        setTimeout(() => {
          window.location.reload();
        }, 5 * 1000);
      }

      return response;
    },
    (err) => {
      throw err;
    }
  );
};

export default setAuthToken;
