import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import moment from 'moment';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import _ from 'lodash';
import Price from '../../utils/Price';

const COLOR_GREEN = '#71ff5e';
const COLOR_RED = '#ff4949';
const COLOR_XAXIS = '#c9d884';
const COLOR_YAXIS = '#8884d8';

const GlobalEquityCurve = ({ tradesArr }) => {
  const [dataArr, setDataArr] = useImmer([]);

  // console.log({ tradesArr });

  useEffect(() => {
    if (Array.isArray(tradesArr)) {
      //sort as per time
      let tempData = _.sortBy(tradesArr, (o) =>
        Date.parse(o['Closing Date Time'])
      ).map((itemObj) => ({
        Time: moment(itemObj['Closing Date Time']).format('hh:mm'),
        PnL: Number(itemObj['Net PnL']),
      }));

      // for two trades at the same time (correct to minutes or on same candle), add these two trades into one
      tempData = Object.values(
        tempData.reduce((acc, { Time, PnL }) => {
          acc[Time] = {
            Time,
            PnL: (acc[Time] ? acc[Time].PnL : 0) + PnL,
          };
          return acc;
        }, {})
      );

      let tempSum = 0;
      tempData = tempData.map((itemObj) => {
        tempSum += Number(itemObj['PnL']);
        return {
          Time: itemObj['Time'], //hh:mm
          CumPnL: Price(tempSum), //cumulative PnL
        };
      });

      // console.log({ tempData });

      setDataArr(tempData);
    }
  }, [tradesArr]);

  const gradientOffset = () => {
    const dataMax = Math.max(...dataArr.map((i) => i.CumPnL));
    const dataMin = Math.min(...dataArr.map((i) => i.CumPnL));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  return (
    <>
      {/* For whole CSV Equity Curve */}
      <div>Overall Equity Curve</div>
      <ResponsiveContainer width="100%" height={400} className="testR1">
        <AreaChart
          width={500}
          height={300}
          data={dataArr}
          syncId="Time"
          margin={{
            top: 10,
            right: window.innerWidth <= 500 ? 10 : 30,
            left: window.innerWidth <= 500 ? -30 : 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Time" tick={{ fill: COLOR_XAXIS }} />
          <YAxis tick={{ fill: COLOR_YAXIS }} />
          <Legend />
          <RechartsTooltip />
          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor={COLOR_GREEN} stopOpacity={1} />
              <stop offset={off} stopColor={COLOR_RED} stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="CumPnL"
            stroke={COLOR_YAXIS}
            fill="url(#splitColor)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default GlobalEquityCurve;
