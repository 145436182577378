import axios from 'axios';
import { updateConfig } from '.';
import { SET_ECOM_LEADS } from '../action-types';

export const getEcomLeads =
  (query = '', selectedVertical, callback) =>
  async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      const res = await axios.get(`/api/v1/ecom-leads?${query}`);

      const ecomLeads = res.data && res.data.data;
      const total = res.data && res.data.total;
      const limit = res.data && res.data.limit;
      const page = res.data && res.data.page;

      dispatch({
        type: SET_ECOM_LEADS,
        payload: {
          page,
          ecomLeads,
          total,
          limit,
          selectedVertical,
        },
      });

      callback(true);
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };

export const getEcomSearchSuggestion =
  (bioSearchText, callback) => async (dispatch) => {
    try {
      let suggestionArr = [];

      if ((bioSearchText || '').length >= 3) {
        const res = await axios.post('/api/v1/ecom-leads/vertical-suggestion', {
          suggest: bioSearchText,
        });

        if (res.data && Array.isArray(res.data.data)) {
          suggestionArr = res.data.data;
        }
      }

      callback(true, suggestionArr);
    } catch (err) {
      console.log('Error: ', err.message);
      callback(false);

      dispatch(
        updateConfig({
          message: err.response?.data?.message || err.message || 'Error',
          isError: true,
        })
      );
    }
  };
