import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import moment from 'moment';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import _ from 'lodash';
import Price from '../../utils/Price';
import { Drawer } from 'antd';

import MyPieChart from './MyPieChart';
import ChartLib from './chart-lib';
import { TRADE_DRAWER_POSITION } from '../../constants';
import { createGlobalStyle } from 'styled-components';

const COLOR_GREEN = '#71ff5e';
const COLOR_RED = '#ff4949';
const COLOR_XAXIS = '#c9d884';
const COLOR_YAXIS = '#8884d8';

// global drawer make it dull height
const GlobalDrawerStyle = createGlobalStyle`

&&&.global-drawer.ant-drawer .ant-drawer-content-wrapper {
    height:95% !important
  }
`;

const GlobalBarChart = ({ tradesArr }) => {
  const [overallDataArr, setOverallDataArr] = useImmer([]);
  const [analyzeTradeDrawer, setAnalyzeTradeDrawer] = useImmer(false);

  const [selectedAsset, setSelectedAsset] = useImmer('');

  // selected asset data arr
  const selectedAssetDataArr = _.filter(
    tradesArr,
    (o) => o.Asset === selectedAsset
  );

  // console.log({ tradesArr });

  useEffect(() => {
    if (Array.isArray(tradesArr)) {
      const tempData = _(tradesArr)
        .groupBy('Asset')
        .map((objs, key) => ({
          Asset: key,
          PnL: Price(_.sumBy(objs, (item) => Number(item['Net PnL']))),
        }))
        .value();
      // console.log({ tempData });

      setOverallDataArr(tempData);
    }
  }, [tradesArr]);

  return (
    <>
      {/* for global drawer style*/}
      <GlobalDrawerStyle />
      <Drawer
        title="Trade Analyzer"
        placement={TRADE_DRAWER_POSITION}
        closable={true}
        onClose={() => {
          setSelectedAsset('');
          setAnalyzeTradeDrawer(false);
        }}
        open={analyzeTradeDrawer}
        key={TRADE_DRAWER_POSITION}
        size={'large'}
        className="global-drawer"
      >
        {/* one pair info such as call/put/win ratio, etc*/}
        {/* show individual pair info */}
        {/* SHow charts using self hosted chart */}
        <div>{selectedAsset} Chart</div>
        <ChartLib
          selectedAsset={selectedAsset}
          tradesArr={selectedAssetDataArr}
        />
        <hr />
        {/* selected Asset Pie Chart */}
        <div>
          {selectedAsset} Chart: Call/Put, Total: {selectedAssetDataArr.length}
        </div>
        {/* get only selected asset data  */}
        <MyPieChart tradesArr={selectedAssetDataArr} />
      </Drawer>

      {/* For whole CSV View */}
      <div>Overall Bar Chart</div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={overallDataArr}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Asset" tick={{ fill: COLOR_XAXIS }} />
          <YAxis tick={{ fill: COLOR_YAXIS }} />
          <RechartsTooltip />
          <Legend />
          <Bar
            dataKey="PnL"
            fill={COLOR_YAXIS}
            onClick={(data, idx) => {
              // console.log('clicked: ', data, idx);
              setSelectedAsset(data.Asset);
              setAnalyzeTradeDrawer(!analyzeTradeDrawer);
            }}
          >
            {overallDataArr.map((entry, index) => {
              const color = entry.PnL > 0 ? COLOR_GREEN : COLOR_RED;
              return <Cell fill={color} cursor="pointer" key={index} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default GlobalBarChart;
