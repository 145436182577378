import { Button } from 'antd';

import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import moment from 'moment';
import chartDataJson from '../../data/_kc.json';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
  Cell,
  LabelList,
} from 'recharts';

const Crypto = (props) => {
  const [chartDataArr, setChartDataArr] = useImmer([]);

  useEffect(() => {
    let tempData = [];
    Array.isArray(chartDataJson.data) &&
      chartDataJson.data.map((itemArr, idx) => {
        tempData.push({
          date: Number(itemArr[0]),
          unknown: parseInt(itemArr[5]),
          volume: parseInt(itemArr[6]),
          type: idx + 1,
          open: Number(itemArr[1]),
          close: Number(itemArr[2]),
        });
      });

    setChartDataArr(tempData.reverse());
  }, []);

  if (true) {
    return (
      <span>
        Error due to update of Recharts Lib, maybe fix later. Focus on Important
        Task Now .
      </span>
    );
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: 'white',
            border: '1px solid #bbc',
            padding: '8px',
          }}
        >
          <p
            style={{
              color:
                payload[0].payload.close - payload[0].payload.open >= 0
                  ? 'green'
                  : 'red',
            }}
          >{`Date: ${moment
            .utc(payload[0].payload.date * 1000)
            .format('YYYY/MM/DD - HH:mm:ss')}, ${label} bars from Now`}</p>
          <p
            style={{ color: payload[0].fill }}
          >{`Volume : ${payload[0].value}`}</p>

          <p
            style={{ color: payload[1].fill }}
          >{`Unknown: ${payload[1].value}`}</p>

          <p style={{ color: payload[1].fill }}>{`Unknown is ${(
            parseFloat((payload[0].value - payload[1].value) * 100) /
            payload[0].value
          ).toFixed(2)}  % less`}</p>
        </div>
      );
    }

    return null;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value, type } = props;
    const radius = 17;

    const item = chartDataArr[chartDataArr.length - type];

    const pc = Math.round(((item.volume - item.unknown) * 100) / item.volume);

    return (
      <g>
        <circle
          cx={x + width / 2}
          cy={y + radius - 35}
          r={radius}
          fill="#8884d8"
        />
        <text
          x={x + width / 2}
          y={y + radius - 35}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {pc}
        </text>
      </g>
    );
  };

  return (
    <div style={{ padding: '3%', width: '100%', height: '100%' }}>
      <div>Crypto Analyze</div>

      {/* Analyze crypto */}
      {/* <Button
        type="primary"
        onClick={() => {
          //analyze
        }}
        style={{ marginRight: '12px' }}
      >
        Analyze
      </Button> */}

      {/* chart */}
      <div
        style={{
          width: '90vw',
          height: '80vh',
          overflow: 'auto',
        }}
      >
        {/* <ResponsiveContainer width="100vw" height="100vh"> */}
        <BarChart
          dataKey="unknown"
          fill="#8884d8"
          barSize={30}
          width={30000}
          height={700}
          data={chartDataArr}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis dataKey="volume" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="volume" fill="#8884d8">
            {chartDataArr.map((item, idx) => (
              <Cell
                key={idx}
                fill={item.close - item.open >= 0 ? '#24ae8f' : '#ed6e72'}
              />
            ))}
          </Bar>
          <Bar dataKey="unknown" fill="#1f1c04">
            <LabelList
              dataKey="unknown"
              content={renderCustomizedLabel}
              position="top"
            />
          </Bar>
        </BarChart>
        {/* </ResponsiveContainer> */}
      </div>
    </div>
  );
};

export default Crypto;
