import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import moment from 'moment';

import _ from 'lodash';
import Price from '../../utils/Price';
import TradingViewWidget from './TradingViewWidget';
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tag,
  TimePicker,
  message,
} from 'antd';
import { useActions } from '../../hooks/useActions';
import SessionTimeSeries from './SessionTimeSeries';
import { _DEV_ENV_ } from '../../constants';
import { RedoOutlined } from '@ant-design/icons';
import { tryParseJSON } from '../../utils/tryParseJSON';

// const COLOR_GREEN = '#71ff5e';
// const COLOR_RED = '#ff4949';
// const COLOR_XAXIS = '#c9d884';
// const COLOR_YAXIS = '#8884d8';

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const SessionTimeModal = ({
  openSessionModal,
  setOpenSessionModal,
  localSelectedDate, //local ant date in DD-MM-YYYY
  selectedDateData, //{broker, ...., session_json, ..csv_json.,,, trade_date}
  tradesArr, // selectedDateData.csv_json
}) => {
  const { uploadTradeCSVJSONString, getTradeCSVJSONString } = useActions();

  const [mLocalSelectedDate, setMLocalSelectedDate] =
    useImmer(localSelectedDate);

  const [mSelectedDateData, setMSelectedDateData] = useImmer(selectedDateData);

  const [mTradesArr, setMTradesArr] = useImmer(tradesArr);

  // console.log({ mSelectedDateData, mTradesArr, mLocalSelectedDate });
  // session data object
  //  news release data is the session object here:
  //{ date: dayDate, session_data: [{ name: "USD",star: "1", time: "10:15"}, {name:"JPY:EUR", star:"3", "03:02"}]}
  const [newsDataArr, setNewsDataArr] = useImmer([]);

  // DB news date from session_json.date
  const [newsDate, setNewsDate] = useImmer();

  const [tradeId, setTradeId] = useImmer(null);

  useEffect(() => {
    init();
  }, [mLocalSelectedDate, mSelectedDateData, mTradesArr]);

  // console.log({ tradeId });

  // console.log({
  //   newsDate,
  //   localSelectedDate,
  // });

  // console.log({ newsDataArr });

  // const [pair, setPair] = useImmer('');
  // const [time, setTime] = useImmer();
  // importance star: 1,2,3 .
  //3 is highest, 1 is lowest of importance
  // const [star, setStar] = useImmer();

  // hide news event list
  const [hideNewsList, setHideNewsList] = useImmer(_DEV_ENV_ ? true : true);
  // toggle timeline b/n sessions
  const [toggleTimelines, setToggleTimelines] = useImmer(
    _DEV_ENV_ ? !true : !true
  );

  // console.log({ time });

  const init = () => {
    const tempTradeId = (mSelectedDateData && mSelectedDateData.id) || null;
    setTradeId(tempTradeId);

    const getSessionDataObj =
      (mSelectedDateData && tryParseJSON(mSelectedDateData.session_json)) || {};
    let selectedSessionDataArr = getSessionDataObj.session_data || [];

    // sort news session data
    selectedSessionDataArr.sort((a, b) => {
      // convert a.time to mins
      let aTime = a.time.split(':');
      aTime = parseInt(aTime[0]) * 60 + parseInt(aTime[1]);

      // convert b.time to mins
      let bTime = b.time.split(':');
      bTime = parseInt(bTime[0]) * 60 + parseInt(bTime[1]);

      return parseInt(aTime) - parseInt(bTime);
    });

    setNewsDataArr(selectedSessionDataArr);

    console.log(
      '---------------> total news arr: ',
      selectedSessionDataArr.length
    );

    const selectedSessionDBDate = getSessionDataObj.date;
    setNewsDate(selectedSessionDBDate);
  };

  // incoming prop localSelectedDate is dateStr: DD-MM-YYYY
  // nextPrev: 1 means next , -1 means prev, 0 means current selected date
  const getNextPrevDateData = (nextPrev, today = false) => {
    let dateStr = moment(mLocalSelectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
    // console.log({ dateStr });

    if (nextPrev === 1) {
      dateStr = moment(dateStr, 'DD-MM-YYYY')
        .add(1, 'days')
        .format('DD-MM-YYYY');
    } else if (nextPrev === -1) {
      dateStr = moment(dateStr, 'DD-MM-YYYY')
        .add(-1, 'days')
        .format('DD-MM-YYYY');
    } else if (nextPrev !== 0 && today) {
      dateStr = moment().format('DD-MM-YYYY');
    } else if (nextPrev !== 0 && !today) {
      return;
    }

    // console.log('---------------temp: ', dateStr);

    // return;

    // return;
    // let tempSelectedDateData = {}; ////this is incoming prop selectedDateData //from server, selected date obj {broker, ...., session_json, ..csv_json.,,, trade_date}
    //default is today's date
    getTradeCSVJSONString(dateStr, (selectedMonthDataArr) => {
      Array.isArray(selectedMonthDataArr) &&
        selectedMonthDataArr.map((itemObj) => {
          if (moment(itemObj.trade_date).format('DD-MM-YYYY') === dateStr) {
            // console.log('im here -------------->');

            setMSelectedDateData(itemObj); //{broker, ...., session_json, ..csv_json.,,, trade_date}

            setMTradesArr((itemObj && tryParseJSON(itemObj.csv_json)) || []);
          }
        });

      setMLocalSelectedDate(dateStr);
    });

    // let setSelectedDateTradeDataArr =
    //   (tempSelectedDateData && tryParseJSON(tempSelectedDateData.csv_json)) ||
    //   []; //this is incoming prop tradesArr

    // setMTradesArr(
    //   (tempSelectedDateData && tryParseJSON(tempSelectedDateData.csv_json)) ||
    //     []
    // );
  };

  return (
    <>
      <Modal
        title={
          <div>
            Session Modal
            <span style={{ marginLeft: '12px' }}>
              {moment().format('DD-MM-YYYY') === mLocalSelectedDate && (
                <Tag color="red">TODAY</Tag>
              )}

              {moment().format('DD-MM-YYYY') !== mLocalSelectedDate && (
                <Tag
                  color="green"
                  onClick={() => {
                    getNextPrevDateData(undefined, true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  GO TO TODAY
                </Tag>
              )}
              <Tag color="yellow">
                {moment(mLocalSelectedDate, 'DD-MM-YYYY').format('dddd')}
              </Tag>
              <Tag color={'green'}>{mLocalSelectedDate}</Tag>

              {newsDataArr.length > 0 && mLocalSelectedDate !== newsDate && (
                <span>
                  <Tag color="red">Date and DB News Data Not Match</Tag>
                  <Tag color="red">{newsDate}</Tag>
                </span>
              )}

              <div style={{ marginTop: '8px' }}>
                <Button
                  onClick={() => {
                    getNextPrevDateData(-1);
                  }}
                >
                  Prev
                </Button>

                <Button
                  onClick={() => {
                    getNextPrevDateData(1);
                  }}
                  style={{ marginLeft: '12px' }}
                >
                  Next
                </Button>
              </div>
            </span>
          </div>
        }
        open={openSessionModal}
        onOk={() => setOpenSessionModal(!openSessionModal)}
        onCancel={() => setOpenSessionModal(!openSessionModal)}
        width={'100%'}
        // destroyOnClose={true}
      >
        {/* time picker */}

        {/* <div> */}
        {/* currency pair */}
        {/* <Input
            placeholder="Currency Pair"
            onChange={(e) => {
              console.log(e.target.value);
              setPair(e.target.value.trim());
            }}
            style={{ width: '200px', marginRight: '22px' }}
            value={pair}
          /> */}
        {/* star */}
        {/* <Select
            defaultValue=""
            style={{
              width: 220,
            }}
            placeholder="select news impact"
            allowClear
            options={[
              {
                value: '1',
                label: '1',
              },
              {
                value: '2',
                label: '2',
              },
              {
                value: '3',
                label: '3',
              },
            ]}
            onChange={(value) => {
              setStar(value);
              // console.log(value);
            }}
          /> */}
        {/* time */}
        {/* <TimePicker
            defaultValue={moment(new Date(), 'HH:mm')}
            format={'HH:mm'}
            onChange={(time, timeString) => {
              console.log(timeString);
              setTime(timeString);
            }}
          /> */}

        {/* <Button
            type="primary"
            style={{ marginLeft: '12px' }}
            onClick={() => {
              if (!pair || !star || !time) {
                message.error('Enter pair or star or time');
                return;
              }
              setNewsDataArr([...newsDataArr, { name: pair, time, star }]);
            }}
          >
            Add
          </Button> */}
        {/* </div> */}

        {/* binary time series sessions */}
        {!toggleTimelines &&
          newsDataArr.length > 0 &&
          mLocalSelectedDate === newsDate && (
            <div style={{ marginBottom: '250px' }}>
              <SessionTimeSeries
                newsDataArr={newsDataArr}
                selectedDate={mLocalSelectedDate}
                tradesArr={mTradesArr}
                title={'Available Binary Option Trading Session'}
                showBinarySession={true}
              />
            </div>
          )}
        {/* upload session btn */}

        {/* <div style={{ marginTop: '100px' }}>
          <Button
            onClick={() => {
              if (!newsDataArr) {
                message.error('Enter non empty session');
                return;
              }

              uploadTradeCSVJSONString(
                localSelectedDate,
                '',
                newsDataArr, //session json string
                false, //whether to fetch news from remote website
                () => {
                  // setUploadClicked(false);
                }
              );
            }}
            style={{ margin: '12px 0' }}
            type="primary"
            // disabled={!uploadClicked}
          >
            Upload Session
          </Button>
        </div> */}
        {/* Update News Session For this Date */}
        <div style={{ marginTop: '100px' }}>
          <Button
            onClick={() => {
              uploadTradeCSVJSONString(
                mLocalSelectedDate,
                '',
                '', //session json string
                true, //whether to fetch news from remote website
                tradeId, //trade id,
                undefined, //risk amount
                (cb) => {
                  // console.log('im hereeeee -----');
                  cb && getNextPrevDateData(0);
                }
              );
            }}
            style={{ margin: '12px 0' }}
            type="primary"
            disabled={
              mLocalSelectedDate && newsDate && mLocalSelectedDate !== newsDate
            }
          >
            <RedoOutlined />
            &nbsp;Update Economic Calender For&nbsp;
            <Tag color="green">{mLocalSelectedDate}</Tag>
          </Button>
        </div>

        {/* toggle binary timeline */}
        <div style={{ marginTop: '100px' }}>
          <Button
            onClick={() => {
              setToggleTimelines(!toggleTimelines);
            }}
            style={{ margin: '4px 0' }}
            type="primary"
          >
            Other Timeline
          </Button>
        </div>
        {/* show list of news session data */}
        <div
          style={{
            marginTop: '20px',
            visibility:
              newsDataArr.length > 0 && mLocalSelectedDate !== newsDate
                ? 'hidden'
                : 'visible',
          }}
        >
          {/* Fx Timeline */}
          {/* time series sessions */}
          {toggleTimelines &&
            newsDataArr.length > 0 &&
            mLocalSelectedDate === newsDate && (
              <div style={{ marginBottom: '250px' }}>
                <SessionTimeSeries
                  newsDataArr={newsDataArr}
                  tradesArr={mTradesArr}
                  title={'Available Fx Trading Session'}
                  showBinarySession={false}
                />
              </div>
            )}

          <Button
            type="warn"
            style={{
              margin: '12px 0',
              color: newsDataArr.length ? 'green' : 'red',
            }}
            onClick={() => {
              setHideNewsList(!hideNewsList);
            }}
          >
            Toggle News List {Array.isArray(newsDataArr) && newsDataArr.length}
          </Button>
          <div style={{ display: hideNewsList && 'none' }}>
            {Array.isArray(newsDataArr) &&
              newsDataArr.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      width: '100%',
                      background: item.star >= 3 && '#bbba6e95',
                      maxWidth: '200px',
                    }}
                  >
                    <div style={{ marginRight: '20px', width: '70px' }}>
                      {item.time || ''}
                    </div>
                    <div
                      style={{
                        marginRight: '20px',
                        width: '20px',
                      }}
                    >
                      {item.star || ''}
                    </div>
                    <div style={{ marginRight: '20px' }}>{item.name || ''}</div>

                    {/* <Popconfirm
                      title="Are you sure to delete this task?"
                      onConfirm={() => {
                        let tempDataArr = [];

                        newsDataArr.map(
                          (tempItem, tempIdx) =>
                            tempIdx !== idx && tempDataArr.push(tempItem)
                        );

                        setNewsDataArr(tempDataArr);
                      }}
                      onCancel={undefined}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="danger" style={{ marginLeft: '20px' }}>
                        Clear
                      </Button>
                    </Popconfirm> */}
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SessionTimeModal;
