import setAuthToken from '../../utils/setAuthToken';
import { SET_CURRENT_USER, SET_TRADE_CONFIG } from '../action-types';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { updateConfig } from '.';
import moment from 'moment';
import { tryParseJSON } from '../../utils/tryParseJSON';

// delete a trade csv from DB
export const deleteSelectedDayTrade =
  (selectedDate, callback) => async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      const res = await axios.delete(`/api/v1/trade-csv`, {
        params: { trade_date: selectedDate },
      });

      console.log(res.data.data);
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };

//  get json string from DB
export const getTradeCSVJSONString =
  (selectedDateStr, callback) => async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      // console.log('get from DB here ------------->');
      const tempDate = moment(selectedDateStr, 'DD-MM-YYYY');

      const dateFrom = `${tempDate.startOf('M').format('D')}-${tempDate.format(
        'M'
      )}-${tempDate.format('YYYY')}`;

      const dateTo = `${tempDate.endOf('M').format('D')}-${tempDate.format(
        'M'
      )}-${tempDate.format('YYYY')}`;

      // console.log({ dateFrom, dateTo });

      let response = await axios.get(`/api/v1/trade-csv`, {
        params: { dateFrom, dateTo },
      });

      if (callback) {
        callback(response.data);
      }
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };

//  get json string from DB for whole one year
export const getYearlyTradeCSVJSONString =
  (selectedDateStr, callback) => async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      // console.log('get from DB here ------------->');
      const tempDate = moment(selectedDateStr, 'DD-MM-YYYY').format('YYYY');

      const dateFrom = `1-1-${tempDate}`;

      const dateTo = `31-12-${tempDate}`;

      // console.log({ dateFrom, dateTo });

      let response = await axios.get(`/api/v1/trade-csv`, {
        params: { dateFrom, dateTo, mode: 'year' },
      });

      if (callback) {
        callback(response.data);
      }
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };

// upload json string from DB
// remotelyGetNews: whether to fetch news from remote website
export const uploadTradeCSVJSONString =
  (
    selectedDate,
    csvArr,
    sessionArrStr,
    remotelyGetNews = false,
    tradeId = null,
    riskAmt = undefined,
    callback
  ) =>
  async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      if (!csvArr === '' && !tryParseJSON(JSON.stringify(csvArr))) {
        console.log(
          'before upload, err parsing trade csv json, date: ',
          selectedDate
        );
        return;
      }

      if (
        !remotelyGetNews &&
        !sessionArrStr === '' &&
        !tryParseJSON(JSON.stringify(sessionArrStr))
      ) {
        console.log(
          'before upload, err parsing session json, date: ',
          selectedDate
        );
        return;
      }

      const response = await axios.post(`/api/v1/trade-csv`, {
        ...(csvArr && { csv_json: JSON.stringify(csvArr) }),
        ...(tradeId && { trade_id: tradeId }),
        trade_date: selectedDate,
        broker: 'iq option',
        ...(sessionArrStr && { session_json: JSON.stringify(sessionArrStr) }),
        remotely_get_news: remotelyGetNews,
        ...(riskAmt && { risk: riskAmt }),
      });

      console.log(response.data);

      if (callback) {
        callback(true);
      }
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };

//  get candles data
export const getCandlesData =
  (asset, fromDate, toDate, callback) => async (dispatch) => {
    try {
      dispatch(updateConfig({ loading: true }));

      let response = await axios.get(`/api/v1/trade-csv/candles`, {
        params: { fromDate, toDate, asset },
      });

      if (callback) {
        callback(response.data && response.data.data);
      }
    } catch (err) {
      console.log('Error: ', err.message);
      callback(err?.response?.data?.message || err.message);
    }

    dispatch(updateConfig({ loading: false }));
  };

//  get qtx real fx data
export const getQtxRealFxData = (callback) => async (dispatch) => {
  try {
    dispatch(updateConfig({ loading: true }));

    let response = await axios.get(`/api/v1/trade-csv/qtx-real-fx-data`);

    if (callback) {
      callback(true, response.data && response.data.data);
    }
  } catch (err) {
    console.log('Error: ', err.message);
    callback(false, err?.response?.data?.message || err.message);
  }

  dispatch(updateConfig({ loading: false }));
};

export const updateTradeConfig = (config) => async (dispatch, getState) => {
  dispatch({
    type: SET_TRADE_CONFIG,
    payload: {
      ...config,
    },
  });
};
